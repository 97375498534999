import dayjs from "dayjs";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import ConfirmDialog from "../../layouts/confirmDialog/confirmDialog.vue";
import { ContractorTypes } from "../../models/emums/contractorTypes";
import { Roles } from "../../models/emums/roles";
import ListItemNumberModel from "../../models/listItemNumberModel";
import ListItemStringModel from "../../models/listItemStringModel";
import LocalStorageApplicationStateDataModel from "../../models/localStorageApplicationStateDataModel";
import ContractorAssetsRestModel from "../../models/rest/contractor/contractorAssetsRestModel";
import ContractorSearchItemModel from "../../models/rest/contractor/contractorSearchItemModel";
import SupervisorAllOrdersItemRestModel from "../../models/rest/supervisor/supervisorAllOrdersItemRestModel";
import SupervisorAllOrdersListRequestModel from "../../models/rest/supervisor/supervisorAllOrdersListRequestModel";
import SupervisorRestModel from "../../models/rest/supervisor/supervisorRestModel";
import FlagIcon from "../custom/flag-icon.vue";

@Component({
    components: {
        "confirm-dialog": ConfirmDialog,
        "flag-icon": FlagIcon,
    }
})

export default class SupervisorAllOrdersList extends Vue {
    loadingDateStartDateLabel = this.$t("supervisor.loadingStartDate");
    loadingDateEndDateLabel = this.$t("supervisor.loadingEndDate");
    unloadingDateStartDateLabel = this.$t("supervisor.unloadingStartDate");
    unloadingDateEndDateLabel = this.$t("supervisor.unloadingEndDate");
    showFiltersMenu = false;
    contractorSenderSearch = "";
    contractorCarrierSearch = "";

    supervisorAllOrdersListHeaders = [
        {
            text: this.$t("supervisor.identificationData"),
            align: "start",
            value: "identificationData",
            sortable: true,
            visible: true
        },
        {
            text: "",
            align: "start",
            value: "loadingCountry",
            sortable: true,
            visible: true
        },
        {
            text: this.$t("supervisor.loadingData"),
            align: "start",
            value: "loadingData",
            sortable: false,
            visible: true
        },
        {
            text: "",
            align: "start",
            value: "unloadingCountry",
            sortable: true,
            visible: true
        },
        {
            text: this.$t("supervisor.unloadingData"),
            align: "start",
            value: "unloadingData",
            sortable: false,
            visible: true
        },
        {
            text: this.$t("supervisor.customer"),
            align: "start",
            value: "customer",
            sortable: true,
            visible: true
        },
        {
            text: this.$t("supervisor.shippingCompany"),
            align: "start",
            value: "shippingCompany",
            sortable: true,
            visible: true
        },
        {
            text: this.$t("supervisor.customerFreight") + ", " + this.$t("supervisor.forwarderFreight"),
            align: "start",
            value: "freightData",
            sortable: false,
            visible: true
        },
        {
            text: this.$t("supervisor.profitValue"),
            align: "start",
            value: "profitValueData",
            sortable: false,
            visible: true
        },
        {
            text: this.$t("supervisor.profitPercentageValue"),
            align: "start",
            value: "profitPercentageValueData",
            sortable: false,
            visible: this.showProfitPercentageValueColumn
        },
        {
            text: this.$t("supervisor.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
            visible: true
        }
    ];

    get showProfitPercentageValueColumn(): boolean {
        return this.currentUserRoles.includes(Roles.HigherDisponentAdmin);
    }

    get computedHeaders(): any[] {
        return this.supervisorAllOrdersListHeaders.filter(x => x.visible == true);
    }

    get localStorageApplicationStateData(): LocalStorageApplicationStateDataModel {
        return this.$store.direct.state.common.localStorageApplicationStateData;
    }

    get orderTypesForFilters(): ListItemNumberModel[] {
        const orderTypes = [];
        this.$store.direct.state.supervisor.supervisorPlaceOrderType.forEach((val: ListItemNumberModel) =>
            orderTypes.push(Object.assign({}, val))
        );
        orderTypes.push({ value: 0, text: "Not set" });

        return orderTypes;
    }

    get vehicleTypes(): ListItemStringModel[] {
        return this.$store.direct.state.supervisor.vehicleTypes;
    }

    get departments(): ListItemNumberModel[] {
        const departments = this.$store.direct.state.contractor.contractorAssets.departments;
        if (departments == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        return departments;
    }

    get searchText(): string {
        return this.$store.direct.state.supervisorAllOrders.supervisorAllOrdersListSearchText;
    }

    set searchText(value: string) {
        this.$store.direct.commit.supervisorAllOrders.setSupervisorAllOrdersListSearchText(value);
    }

    get searchLoadingPlaceCountry(): string {
        return this.$store.direct.state.supervisorAllOrders.supervisorAllOrdersListSearchLoadingPlaceCountry;
    }

    set searchLoadingPlaceCountry(value: string) {
        this.$store.direct.commit.supervisorAllOrders.setSupervisorAllOrdersListSearchLoadingPlaceCountry(value);
    }

    get searchUnloadingPlaceCountry(): string {
        return this.$store.direct.state.supervisorAllOrders.supervisorAllOrdersListSearchUnloadingPlaceCountry;
    }

    set searchUnloadingPlaceCountry(value: string) {
        this.$store.direct.commit.supervisorAllOrders.setSupervisorAllOrdersListSearchUnloadingPlaceCountry(value);
    }

    get searchLoadingPlacePostalCode(): string {
        return this.$store.direct.state.supervisorAllOrders.supervisorAllOrdersListSearchLoadingPlacePostalCode;
    }

    set searchLoadingPlacePostalCode(value: string) {
        this.$store.direct.commit.supervisorAllOrders.setSupervisorAllOrdersListSearchLoadingPlacePostalCode(value);
    }

    get searchUnloadingPlacePostalCode(): string {
        return this.$store.direct.state.supervisorAllOrders.supervisorAllOrdersListSearchUnloadingPlacePostalCode;
    }

    set searchUnloadingPlacePostalCode(value: string) {
        this.$store.direct.commit.supervisorAllOrders.setSupervisorAllOrdersListSearchUnloadingPlacePostalCode(value);
    }

    get searchLoadingPlaceCity(): string {
        return this.$store.direct.state.supervisorAllOrders.supervisorAllOrdersListSearchLoadingPlaceCity;
    }

    set searchLoadingPlaceCity(value: string) {
        this.$store.direct.commit.supervisorAllOrders.setSupervisorAllOrdersListSearchLoadingPlaceCity(value);
    }

    get searchUnloadingPlaceCity(): string {
        return this.$store.direct.state.supervisorAllOrders.supervisorAllOrdersListSearchUnloadingPlaceCity;
    }

    set searchUnloadingPlaceCity(value: string) {
        this.$store.direct.commit.supervisorAllOrders.setSupervisorAllOrdersListSearchUnloadingPlaceCity(value);
    }

    get searchCustomerIdcs(): number[] {
        return this.$store.direct.state.supervisorAllOrders.supervisorAllOrdersListSearchCustomerIdcs;
    }

    set searchCustomerIdcs(value: number[]) {
        this.$store.direct.commit.supervisorAllOrders.setSupervisorAllOrdersListSearchCustomerIdcs(value);
    }

    get searchShippingCompanyIdcs(): number[] {
        return this.$store.direct.state.supervisorAllOrders.supervisorAllOrdersListSearchShippingCompanyIdcs;
    }

    set searchShippingCompanyIdcs(value: number[]) {
        this.$store.direct.commit.supervisorAllOrders.setSupervisorAllOrdersListSearchShippingCompanyIdcs(value);
    }

    get searchLoadingDateStartDate(): string {
        return this.$store.direct.state.supervisorAllOrders.supervisorAllOrdersListSearchLoadingDateStartDate;
    }

    set searchLoadingDateStartDate(value: string) {
        this.$store.direct.commit.supervisorAllOrders.setSupervisorAllOrdersListSearchLoadingDateStartDate(value);
    }

    get searchLoadingDateEndDate(): string {
        return this.$store.direct.state.supervisorAllOrders.supervisorAllOrdersListSearchLoadingDateEndDate;
    }

    set searchLoadingDateEndDate(value: string) {
        this.$store.direct.commit.supervisorAllOrders.setSupervisorAllOrdersListSearchLoadingDateEndDate(value);
    }

    get searchUnloadingDateStartDate(): string {
        return this.$store.direct.state.supervisorAllOrders.supervisorAllOrdersListSearchUnloadingDateStartDate;
    }

    set searchUnloadingDateStartDate(value: string) {
        this.$store.direct.commit.supervisorAllOrders.setSupervisorAllOrdersListSearchUnloadingDateStartDate(value);
    }

    get searchUnloadingDateEndDate(): string {
        return this.$store.direct.state.supervisorAllOrders.supervisorAllOrdersListSearchUnloadingDateEndDate
    }

    set searchUnloadingDateEndDate(value: string) {
        this.$store.direct.commit.supervisorAllOrders.setSupervisorAllOrdersListSearchUnloadingDateEndDate(value);
    }

    get pagerOptions(): any {
        return this.$store.direct.state.supervisorAllOrders.supervisorAllOrdersListPagerOptions;
    }

    set pagerOptions(value: any) {
        this.$store.direct.commit.supervisorAllOrders.setSupervisorAllOrdersListPagerOptions(value);
    }

    get supervisorAllOrdersListLoading(): boolean {
        return this.$store.direct.state.supervisorAllOrders.supervisorAllOrdersListLoading;
    }

    get supervisorAllOrdersList(): SupervisorAllOrdersItemRestModel[] {
        return this.$store.direct.state.supervisorAllOrders.supervisorAllOrdersList;
    }

    get supervisorAllOrdersListTotalCount(): number {
        return this.$store.direct.state.supervisorAllOrders.supervisorAllOrdersListTotalCount;
    }

    get contractorSenderFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisorAllOrders.contractorSenderFound;
    }

    get selectedContractorSenderListForFilter(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisorAllOrders.selectedContractorSenderListForFilter;
    }

    set selectedContractorSenderListForFilter(val: ContractorSearchItemModel[]) {
        this.$store.direct.commit.supervisorAllOrders.setSelectedContractorSenderListForFilter(val);
    }

    get contractorCarrierFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisorAllOrders.contractorCarrierFound;
    }

    get selectedContractorCarrierListForFilter(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisorAllOrders.selectedContractorCarrierListForFilter;
    }

    set selectedContractorCarrierListForFilter(val: ContractorSearchItemModel[]) {
        this.$store.direct.commit.supervisorAllOrders.setSelectedContractorCarrierListForFilter(val);
    }

    get currentUserRoles(): string[] {
        return this.$store.direct.state.auth.userRoles;
    }

    get contractorAssets(): ContractorAssetsRestModel {
        return this.$store.direct.state.contractor.contractorAssets;
    }

    get userIsAccountant(): boolean {
        if (this.currentUserRoles.includes(Roles.Accountant) || this.currentUserRoles.includes(Roles.AccountantAdmin)) {
            return true;
        }
        return false;
    }

    get backToSupervisorAllOrdersList(): boolean {
        return this.$store.direct.state.supervisorAllOrders.backToSupervisorAllOrdersList;
    }

    set backToSupervisorAllOrdersList(val: boolean) {
        this.$store.direct.commit.supervisorAllOrders.setBackToSupervisorAllOrdersList(val);
    }

    get hasOrHasNotOrAllTypeList(): ListItemNumberModel[] {
        return this.$store.direct.state.common.hasOrHasNotOrAllTypeList;
    }

    @Watch("contractorSenderSearch")
    async contractorSenderSearchLoadItems(val: string) {
        await this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [ContractorTypes.Client], contractorPropertiesEnabled: [], source: "supervisorAllOrdersList" });
        if (this.selectedContractorSenderListForFilter.length > 0) {
            this.selectedContractorSenderListForFilter.forEach((x: any) => {
                if (!this.contractorSenderFound.includes(x)) {
                    this.contractorSenderFound.push(x);
                }
            })
        }
    }

    @Watch("contractorCarrierSearch")
    async contractorCarrierSearchLoadItems(val: string) {
        await this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [ContractorTypes.Carrier], contractorPropertiesEnabled: [], source: "supervisorAllOrdersList" });
        if (this.selectedContractorCarrierListForFilter.length > 0) {
            this.selectedContractorCarrierListForFilter.forEach((x: any) => {
                if (!this.contractorCarrierFound.includes(x)) {
                    this.contractorCarrierFound.push(x);
                }
            })
        }
    }

    @Watch("pagerOptions", { deep: true })
    pagerOptionsHandles() {
        this.loadSupervisorAllOrdersList();
    }

    created() {
        if (this.selectedContractorSenderListForFilter == null || this.selectedContractorSenderListForFilter == undefined) {
            this.selectedContractorSenderListForFilter = [];
        }
        if (this.selectedContractorCarrierListForFilter == null || this.selectedContractorCarrierListForFilter == undefined) {
            this.selectedContractorCarrierListForFilter = [] as ContractorSearchItemModel[];
        }
    }

    async setIsSupervisorPreliminary(isPreliminary: boolean) {
        await this.$store.direct.commit.supervisor.setIsSupervisorPreliminary(isPreliminary);
    }

    getSupervisorProfitItemStyle(supervisorProfit: number) {
        if (supervisorProfit > 0) {
            return "teal--text text--accent-4";
        }
        else if (supervisorProfit < 0) {
            return "red--text";
        }
        else {
            return "";
        }
    }

    getSupervisorItemStyle(item: SupervisorRestModel) {
        if (item.isStorno) {
            return "red--text text-decoration-line-through";
        }
        return "";
    }

    formatDateTime(time: any) {
        if (time != null) {
            return dayjs(time).format("YYYY-MM-DD HH:mm");
        }
    }

    searchClick() {
        this.loadSupervisorAllOrdersList();
    }

    clearSearchClick() {
        this.searchText = "";
        this.loadSupervisorAllOrdersList();
    }

    clearSearchLoadingPlaceCountryClick() {
        this.searchLoadingPlaceCountry = "";
        this.loadSupervisorAllOrdersList();
    }

    clearSearchUnloadingPlaceCountryClick() {
        this.searchUnloadingPlaceCountry = "";
        this.loadSupervisorAllOrdersList();
    }

    clearSearchPlacePostalCodeClick() {
        this.searchLoadingPlacePostalCode = "";
        this.loadSupervisorAllOrdersList();
    }

    clearSearchUnloadingPlacePostalCodeClick() {
        this.searchUnloadingPlacePostalCode = "";
        this.loadSupervisorAllOrdersList();
    }

    clearSearchPlaceCityClick() {
        this.searchLoadingPlaceCity = "";
        this.loadSupervisorAllOrdersList();
    }

    clearSearchUnloadingPlaceCityClick() {
        this.searchUnloadingPlaceCity = "";
        this.loadSupervisorAllOrdersList();
    }


    clearSearchContractorSenderClick() {
        this.selectedContractorSenderListForFilter = [] as ContractorSearchItemModel[];
        this.loadSupervisorAllOrdersList();
    }

    clearSearchContractorCarrierClick() {
        this.selectedContractorCarrierListForFilter = [] as ContractorSearchItemModel[];
        this.loadSupervisorAllOrdersList();
    }

    clearDateTimePicker(clearableValue: string) {
        if (clearableValue == "searchLoadingDateStartDateValue") {
            this.searchLoadingDateStartDate = "";
        }
        if (clearableValue == "searchLoadingDateEndDateValue") {
            this.searchLoadingDateEndDate = "";
        }
        if (clearableValue == "searchUnloadingDateStartDateValue") {
            this.searchUnloadingDateStartDate = "";
        }
        if (clearableValue == "searchUnloadingDateEndDateValue") {
            this.searchUnloadingDateEndDate = "";
        }
        this.loadSupervisorAllOrdersList();
    }

    clearAllComplexFilters() {
        this.searchLoadingPlaceCountry = "";
        this.searchUnloadingPlaceCountry = "";
        this.searchLoadingPlacePostalCode = "";
        this.searchUnloadingPlacePostalCode = "";
        this.searchLoadingPlaceCity = "";
        this.searchUnloadingPlaceCity = "";
        this.searchCustomerIdcs = [] as number[];
        this.searchShippingCompanyIdcs = [];
        this.localStorageApplicationStateData.supervisorListSelectedDepartments = [] as number[];
        this.selectedContractorCarrierListForFilter = [] as ContractorSearchItemModel[];
        this.selectedContractorSenderListForFilter = [] as ContractorSearchItemModel[];

        this.loadSupervisorAllOrdersList();
    }

    editSupervisorRowClick(supervisor: SupervisorRestModel) {
        if (window.getSelection()?.toString()) {
            return
        }
        this.backToSupervisorAllOrdersList = true;
        this.$router.push({ path: "/supervisor/edit/" + supervisor.id });
    }

    editSupervisorClick(supervisorId: number, isSupervisorInHistoricalState: boolean) {
        if (isSupervisorInHistoricalState) {
            return "/supervisor/edit/" + supervisorId + "/" + "history";
        }
        return "/supervisor/edit/" + supervisorId;
    }

    showSupervisorAttachmentClick(supervisorId: number) {
        return "/supervisor/attachment-list/" + supervisorId;
    }

    loadSupervisorAllOrdersList() {
        if (this.selectedContractorSenderListForFilter != undefined && this.selectedContractorSenderListForFilter.length > 0) {
            this.searchCustomerIdcs = this.selectedContractorSenderListForFilter.map(x => x.value);
        }
        else {
            this.searchCustomerIdcs = [];
        }
        if (this.selectedContractorCarrierListForFilter != undefined && this.selectedContractorCarrierListForFilter.length > 0) {
            this.searchShippingCompanyIdcs = this.selectedContractorCarrierListForFilter.map(x => x.value);
        }
        else {
            this.searchShippingCompanyIdcs = [];
        }

        this.$store.direct.dispatch.supervisorAllOrders.loadSupervisorAllOrdersList({
            requestModel: this.prepareSupervisorAllOrdersListRequestModel()
        });
    }

    hideFiltersMenuClick() {
        this.showFiltersMenu = false
    }

    prepareSupervisorAllOrdersListRequestModel(): SupervisorAllOrdersListRequestModel {
        return {
            page: this.pagerOptions.page,
            pageSize: this.pagerOptions.itemsPerPage,
            sortBy: this.pagerOptions.sortBy[0],
            sortDesc: this.pagerOptions.sortDesc[0],
            searchText: this.searchText,
            searchLoadingPlaceCountry: this.searchLoadingPlaceCountry,
            searchUnloadingPlaceCountry: this.searchUnloadingPlaceCountry,
            searchLoadingPlacePostalCode: this.searchLoadingPlacePostalCode,
            searchUnloadingPlacePostalCode: this.searchUnloadingPlacePostalCode,
            searchLoadingPlaceCity: this.searchLoadingPlaceCity,
            searchUnloadingPlaceCity: this.searchUnloadingPlaceCity,
            searchCustomerIdcs: this.searchCustomerIdcs,
            searchShippingCompanyIdcs: this.searchShippingCompanyIdcs,
            searchLoadingDateStartDate: this.searchLoadingDateStartDate,
            searchLoadingDateEndDate: this.searchLoadingDateEndDate,
            searchUnloadingDateStartDate: this.searchUnloadingDateStartDate,
            searchUnloadingDateEndDate: this.searchUnloadingDateEndDate,
           
        } as SupervisorAllOrdersListRequestModel
    }
}
