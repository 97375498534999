import Vue from "vue";
import { Component } from "vue-property-decorator";
import { axiosInstance } from "../../../plugins/axios";
import { AxiosResponse, AxiosError } from "axios";
import ContractorIdcsWithInterlanIdcsModel from "../../../models/rest/contractor/contractorIdcsWithInterlanIdcsModel";

@Component
export default class ImportContractorPolicies extends Vue {
    stepperState = 1;
    contractorIdcsWithInternalIdcs: ContractorIdcsWithInterlanIdcsModel[] = [];
    currentContractorIndex = 0;
    currentContractorIdWithInterlanId = {} as ContractorIdcsWithInterlanIdcsModel;
    currentContractorPercentage = 0;
    contractorIdcsWithInternalIdcsGettingDone = false;
    pauseResumeLabel = "Pause"
    isPaused = false;
    contractorsPoliciesNotImported: ContractorIdcsWithInterlanIdcsModel[] = [];

    importContractorIdcsWithInterlanIdcs() {
        this.contractorIdcsWithInternalIdcsGettingDone = false;
        axiosInstance
            .get("/api/contractor/get-contractors-interlan-idcs-from-database")
            .then((resp: AxiosResponse) => {
                this.contractorIdcsWithInternalIdcs = resp.data;
                this.contractorIdcsWithInternalIdcsGettingDone = true;
            })
    }

    importContractorPolicy() {
        if (this.currentContractorIndex <= this.contractorIdcsWithInternalIdcs.length && this.isPaused == false) {
            this.currentContractorIdWithInterlanId = this.contractorIdcsWithInternalIdcs[this.currentContractorIndex];
            axiosInstance
                .post("/api/contractor/" + this.currentContractorIdWithInterlanId.id + "/policy/update/" + this.currentContractorIdWithInterlanId.interlanId)
                .then((resp: AxiosResponse) => {

                    this.currentContractorIndex++;
                    this.currentContractorPercentage = Math.floor(this.currentContractorIndex * 100 / this.contractorIdcsWithInternalIdcs.length)
                    this.importContractorPolicy();
                })
                .catch((err: AxiosError) => {
                    this.contractorsPoliciesNotImported.push(this.currentContractorIdWithInterlanId);
                    this.currentContractorIndex++;
                    this.importContractorPolicy();
                })
        }
    }

    step1Click() {
        this.stepperState = 2;
        this.contractorIdcsWithInternalIdcs = [];
        this.importContractorIdcsWithInterlanIdcs();
    }
    step2Click() {
        this.stepperState = 3;
        this.importContractorPolicy();
    }
    pauseResumeClick() {
        this.isPaused = !this.isPaused
        this.pauseResumeLabel = this.isPaused ? "Resume" : "Pause";
        this.importContractorPolicy();
    }
    resetClick() {
        this.isPaused = false;
        this.contractorIdcsWithInternalIdcs = [];
        this.stepperState = 1;
    }
}
