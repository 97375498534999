import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { ContractorTypes } from "../../../models/emums/contractorTypes";
import ListItemStringModel from "../../../models/listItemStringModel";
import ContractorRestModel from "../../../models/rest/contractor/contractorRestModel";

@Component
export default class ContractorImportDialog extends Vue {
    get contractorImportList(): ContractorRestModel[] {
        return this.$store.direct.state.contractor.contractorImportList;
    }

    importFields: string[] = ["symbol", "name", "streetAndNumber", "country", "postalCode", "city", "tinPrefix", "tin", "statisticalNumber", "paymentTermSale", "paymentTermPurchase", "contractorType", "isLockedAsClient", "ocp"];

    contractorTypeSelectedList(contractor: ContractorRestModel): string[] {
        const result = [] as string[];

        if (contractor.typeIsCarrier) result.push(ContractorTypes.Carrier);
        if (contractor.typeIsOther) result.push(ContractorTypes.Other);
        if (contractor.typeIsPartner) result.push(ContractorTypes.Partner);
        if (contractor.typeIsClient) result.push(ContractorTypes.Client);

        return result;
    }

    colorForContractorProperty(property: string): string {
        if (this.importFields.indexOf(property) > -1) {
            return "";
        }
        return "green";
    }
    colorForContractorImportProperty(property: string): string {
        if (this.importFields.indexOf(property) > -1) {
            return "green";
        }
        return "";
    }

    addToImport(property: string) {
        console.log("addToImport: " + property);
        if (this.importFields.indexOf(property) == -1) {
            this.importFields.push(property);
        }
    }
    removeFromImport(property: string) {
        console.log("removeFromImport: " + property);
        const index = this.importFields.indexOf(property, 0);
        if (index > -1) {
            this.importFields.splice(index, 1);
        }
    }

    get contractorTypeList(): ListItemStringModel[] {
        return this.$store.direct.state.contractor.contractorTypeList;
    } 

    get contractor(): ContractorRestModel {
        return this.$store.direct.state.contractor.contractor;
    }

    get showDialog(): boolean {
        return this.$store.direct.state.contractor.contractorImportShowDialog;
    }

    get tinLoading(): boolean {
        return this.$store.direct.state.contractor.contractorImportLoading;
    }

    closeClick(): void {
        this.$store.direct.dispatch.contractor.closeContactImportDialog();
    }

    async importClick(contractorImport: ContractorRestModel): Promise<void> {
        await this.$store.direct.dispatch.contractor.updateContractorFromImport({ contractorImport: contractorImport, importFields: this.importFields });
    }
}
