import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class Dashboard extends Vue {

    get isLogoVisible(): boolean {
        return this.$store.direct.state.dashboard.isLogoVisible;
    }

    get isOperationAdminGraphVisible(): boolean {
        return this.$store.direct.state.dashboard.isOperationAdminGraphVisible;
    }

    get operationAdminGraphs(): any[] {
        return this.$store.direct.state.dashboard.operationAdminGraphs;
    }

    mounted() {
        this.$store.direct.dispatch.contractor.loadContractorAssets();
        this.$store.direct.dispatch.settlementCommon.loadSettlementAssets();
        this.$store.direct.dispatch.dashboard.selectPanel();
    }

    get usersSelected(): string[] {
        return this.$store.direct.state.dashboard.dashboardOperationUsersSelected;
    }

    set usersSelected(val: string[]) {
        this.$store.direct.commit.dashboard.setDashboardOperationUsersSelected(val);
    }

    get contractorAssets() {
        return this.$store.direct.state.contractor.contractorAssets;
    }

    get userListSorted(): any {
        return this.contractorAssets.crmUsers?.sort((prev, next) => (prev.text > next.text ? 1 : -1));
    }

    operationAdminGraphUpdate() {
        this.$store.direct.dispatch.dashboard.operationAdminGraphUpdate();
    }
}
