import dayjs from "dayjs";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import ConfirmDialog from "../../layouts/confirmDialog/confirmDialog.vue";
import { ContractorTypes } from "../../models/emums/contractorTypes";
import { Roles } from "../../models/emums/roles";
import ListItemNumberModel from "../../models/listItemNumberModel";
import ListItemStringModel from "../../models/listItemStringModel";
import LocalStorageApplicationStateDataModel from "../../models/localStorageApplicationStateDataModel";
import ContractorAssetsRestModel from "../../models/rest/contractor/contractorAssetsRestModel";
import ContractorSearchItemModel from "../../models/rest/contractor/contractorSearchItemModel";
import SupervisorArchiveListRequestModel from "../../models/rest/supervisor/supervisorArchiveListRequestModel";
import SupervisorItemRestModel from "../../models/rest/supervisor/supervisorItemRestModel";
import SupervisorRestModel from "../../models/rest/supervisor/supervisorRestModel";
import FlagIcon from "../custom/flag-icon.vue";

@Component({
    components: {
        "confirm-dialog": ConfirmDialog,
        "flag-icon": FlagIcon,
    }
})

export default class SupervisorArchiveList extends Vue {
    planningDateStartDateLabel = this.$t("supervisor.planningStartDate");
    planningDateEndDateLabel = this.$t("supervisor.planningEndDate");
    realDateStartDateLabel = this.$t("supervisor.doneStartDate");
    realDateEndDateLabel = this.$t("supervisor.doneEndDate");
    showFiltersMenu = false;
    contractorSenderSearch = "";
    contractorCarrierSearch = "";

    supervisorArchiveListHeaders = [
        {
            text: this.$t("supervisor.identificationData"),
            align: "start",
            value: "identificationData",
            sortable: false,
            visible: true
        },
        {
            text: "",
            align: "start",
            value: "loadingCountry",
            sortable: true,
            visible: true
        },
        {
            text: this.$t("supervisor.loadingData"),
            align: "start",
            value: "loadingData",
            sortable: false,
            visible: true
        },
        {
            text: "",
            align: "start",
            value: "unloadingCountry",
            sortable: true,
            visible: true
        },
        {
            text: this.$t("supervisor.unloadingData"),
            align: "start",
            value: "unloadingData",
            sortable: false,
            visible: true
        },
        {
            text: this.$t("supervisor.customerFreight") + ", " + this.$t("supervisor.forwarderFreight"),
            align: "start",
            value: "freightData",
            sortable: false,
            visible: true
        },
        {
            text: this.$t("supervisor.profitValue"),
            align: "start",
            value: "profitValueData",
            sortable: false,
            visible: true
        },
        {
            text: this.$t("supervisor.profitPercentageValue"),
            align: "start",
            value: "profitPercentageValueData",
            sortable: false,
            visible: this.showProfitPercentageValueColumn
        },
        {
            text: this.$t("supervisor.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
            visible: true
        }
    ];

    get showProfitPercentageValueColumn(): boolean {
        return this.currentUserRoles.includes(Roles.HigherDisponentAdmin);
    }

    get computedHeaders(): any[] {
        return this.supervisorArchiveListHeaders.filter(x => x.visible == true);
    }

    get localStorageApplicationStateData(): LocalStorageApplicationStateDataModel {
        return this.$store.direct.state.common.localStorageApplicationStateData;
    }

    get orderTypesForFilters(): ListItemNumberModel[] {
        const orderTypes = [];
        this.$store.direct.state.supervisor.supervisorPlaceOrderType.forEach((val: ListItemNumberModel) =>
            orderTypes.push(Object.assign({}, val))
        );
        orderTypes.push({ value: 0, text: "Not set" });

        return orderTypes;
    }

    get vehicleTypes(): ListItemStringModel[] {
        return this.$store.direct.state.supervisor.vehicleTypes;
    }

    get departments(): ListItemNumberModel[] {
        const departments = this.$store.direct.state.contractor.contractorAssets.departments;
        if (departments == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        return departments;
    }

    get searchLoadInPlace(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchLoadInPlace;
    }

    set searchLoadInPlace(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchLoadInPlace(value);
    }

    get searchLoadOutPlace(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchLoadOutPlace;
    }

    set searchLoadOutPlace(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchLoadOutPlace(value);
    }

    get searchText(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchText;
    }

    set searchText(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchText(value);
    }

    get searchPlaceType(): number {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchPlaceType;
    }

    set searchPlaceType(value: number) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchPlaceType(value);
    }

    get searchUnloadingPlaceType(): number {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchUnloadingPlaceType;
    }

    set searchUnloadingPlaceType(value: number) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchUnloadingPlaceType(value);
    }

    get twoWayPlaceSearching(): boolean {
        return this.$store.direct.state.supervisor.supervisorArchiveListTwoWayPlaceSearching;
    }

    set twoWayPlaceSearching(value: boolean) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListTwoWayPlaceSearching(value);
    }

    get searchPlaceName(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchPlaceName;
    }

    set searchPlaceName(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchPlaceName(value);
    }

    get searchUnloadingPlaceName(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchUnloadingPlaceName;
    }

    set searchUnloadingPlaceName(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchUnloadingPlaceName(value);
    }

    get searchPlaceCountry(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchPlaceCountry;
    }

    set searchPlaceCountry(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchPlaceCountry(value);
    }

    get searchUnloadingPlaceCountry(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchUnloadingPlaceCountry;
    }

    set searchUnloadingPlaceCountry(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchUnloadingPlaceCountry(value);
    }

    get searchPlacePostalCode(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchPlacePostalCode;
    }

    set searchPlacePostalCode(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchPlacePostalCode(value);
    }

    get searchUnloadingPlacePostalCode(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchUnloadingPlacePostalCode;
    }

    set searchUnloadingPlacePostalCode(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchUnloadingPlacePostalCode(value);
    }

    get searchPlaceCity(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchPlaceCity;
    }

    set searchPlaceCity(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchPlaceCity(value);
    }

    get searchUnloadingPlaceCity(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchUnloadingPlaceCity;
    }

    set searchUnloadingPlaceCity(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchUnloadingPlaceCity(value);
    }

    get searchPlaceStreet(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchPlaceStreet;
    }

    set searchPlaceStreet(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchPlaceStreet(value);
    }

    get searchUnloadingPlaceStreet(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchUnloadingPlaceStreet;
    }

    set searchUnloadingPlaceStreet(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchUnloadingPlaceStreet(value);
    }

    get searchCustomerIdcs(): number[] {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchCustomerIdcs;
    }

    set searchCustomerIdcs(value: number[]) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchCustomerIdcs(value);
    }

    get searchShippingCompanyIdcs(): number[] {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchShippingCompanyIdcs;
    }

    set searchShippingCompanyIdcs(value: number[]) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchShippingCompanyIdcs(value);
    }

    get searchRegistrationNumber(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchRegistrationNumber;
    }

    set searchRegistrationNumber(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchRegistrationNumber(value);
    }

    get searchOrderId(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchOrderId;
    }

    set searchOrderId(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchOrderId(value);
    }

    get searchVehicleTypeList(): string[] {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchVehicleTypeList;
    }

    set searchVehicleTypeList(value: string[]) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchVehicleTypeList(value);
    }

    get searchDriver1Name(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchRDriver1Name;
    }

    set searchDriver1Name(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchDriver1Name(value);
    }

    get searchDriver2Name(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchDriver2Name;
    }

    set searchDriver2Name(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchDriver2Name(value);
    }

    get searchComment(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchComment;
    }

    set searchComment(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchComment(value);
    }

    get searchIsADR(): boolean {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchIsADR;
    }

    set searchIsADR(value: boolean) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchIsADR(value);
    }

    get searchTaillift(): boolean {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchTaillift;
    }

    set searchTaillift(value: boolean) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchTaillift(value);
    }

    get searchExtinguisher(): boolean {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchExtinguisher;
    }

    set searchExtinguisher(value: boolean) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchExtinguisher(value);
    }

    get searchNoCostDocument(): boolean {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchNoCostDocument;
    }

    set searchNoCostDocument(value: boolean) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchNoCostDocument(value);
    }

    get searchNoIncomeDocument(): boolean {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchNoIncomeDocument;
    }

    set searchNoIncomeDocument(value: boolean) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchNoIncomeDocument(value);
    }

    get searchPlanningDateStartDate(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchPlanningDateStartDate;
    }

    set searchPlanningDateStartDate(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchPlanningDateStartDate(value);
    }

    get searchUnloadingPlanningDateStartDate(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchUnloadingPlanningDateStartDate;
    }

    set searchUnloadingPlanningDateStartDate(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchUnloadingPlanningDateStartDate(value);
    }

    get searchPlanningDateEndDate(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchPlanningDateEndDate;
    }

    set searchPlanningDateEndDate(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchPlanningDateEndDate(value);
    }

    get searchUnloadingPlanningDateEndDate(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchUnloadingPlanningDateEndDate;
    }

    set searchUnloadingPlanningDateEndDate(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchUnloadingPlanningDateEndDate(value);
    }

    get searchRealDateStartDate(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchRealDateStartDate;
    }

    set searchRealDateStartDate(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchRealDateStartDate(value);
    }

    get searchUnloadingRealDateStartDate(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchUnloadingRealDateStartDate;
    }

    set searchUnloadingRealDateStartDate(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchUnloadingRealDateStartDate(value);
    }

    get searchRealDateEndDate(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchRealDateEndDate;
    }

    set searchRealDateEndDate(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchRealDateEndDate(value);
    }

    get searchUnloadingRealDateEndDate(): string {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchUnloadingRealDateEndDate;
    }

    set searchUnloadingRealDateEndDate(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchUnloadingRealDateEndDate(value);
    }

    get searchIsStorno(): number {
        return this.$store.direct.state.supervisor.supervisorArchiveListSearchIsStorno;
    }

    set searchIsStorno(value: number) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListSearchIsStorno(value);
    }

    get pagerOptions(): any {
        return this.$store.direct.state.supervisor.supervisorArchiveListPagerOptions;
    }

    set pagerOptions(value: any) {
        this.$store.direct.commit.supervisor.setSupervisorArchiveListPagerOptions(value);
    }

    get supervisorArchiveListLoading(): boolean {
        return this.$store.direct.state.supervisor.supervisorArchiveListLoading;
    }

    get supervisorArchiveList(): SupervisorItemRestModel[] {
        return this.$store.direct.state.supervisor.supervisorArchiveList;
    }

    get supervisorArchiveListTotalCount(): number {
        return this.$store.direct.state.supervisor.supervisorArchiveListTotalCount;
    }

    get contractorSenderFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisor.contractorSenderFound;
    }

    get selectedContractorSenderListForFilter(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisor.selectedContractorSenderListForFilter;
    }

    set selectedContractorSenderListForFilter(val: ContractorSearchItemModel[]) {
        this.$store.direct.commit.supervisor.setSelectedContractorSenderListForFilter(val);
    }

    get contractorCarrierFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisor.contractorCarrierFound;
    }

    get selectedContractorCarrierListForFilter(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisor.selectedContractorCarrierListForFilter;
    }

    set selectedContractorCarrierListForFilter(val: ContractorSearchItemModel[]) {
        this.$store.direct.commit.supervisor.setSelectedContractorCarrierListForFilter(val);
    }

    get currentUserRoles(): string[] {
        return this.$store.direct.state.auth.userRoles;
    }

    get contractorAssets(): ContractorAssetsRestModel {
        return this.$store.direct.state.contractor.contractorAssets;
    }

    get userIsAdminOrAccountantAdmin(): boolean {
        if (this.currentUserRoles.includes(Roles.Admin) || this.currentUserRoles.includes(Roles.AccountantAdmin)) {
            return true;
        }
        return false;
    }

    get backToSupervisorArchiveListForSettlement(): boolean {
        return this.$store.direct.state.supervisorForSettlement.backToSupervisorArchiveListForSettlement;
    }

    set backToSupervisorArchiveListForSettlement(val: boolean) {
        this.$store.direct.commit.supervisorForSettlement.setBackToSupervisorArchiveListForSettlement(val);
    }

    get hasOrHasNotOrAllTypeList(): ListItemNumberModel[] {
        return this.$store.direct.state.common.hasOrHasNotOrAllTypeList;
    }

    @Watch("contractorSenderSearch")
    async contractorSenderSearchLoadItems(val: string) {
        await this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [ContractorTypes.Client], contractorPropertiesEnabled: [], source: "supervisor" });
        if (this.selectedContractorSenderListForFilter.length > 0) {
            this.selectedContractorSenderListForFilter.forEach((x: any) => {
                if (!this.contractorSenderFound.includes(x)) {
                    this.contractorSenderFound.push(x);
                }
            })
        }
    }

    @Watch("contractorCarrierSearch")
    async contractorCarrierSearchLoadItems(val: string) {
        await this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [ContractorTypes.Carrier], contractorPropertiesEnabled: [], source: "supervisor" });
        if (this.selectedContractorCarrierListForFilter.length > 0) {
            this.selectedContractorCarrierListForFilter.forEach((x: any) => {
                if (!this.contractorCarrierFound.includes(x)) {
                    this.contractorCarrierFound.push(x);
                }
            })
        }
    }

    @Watch("pagerOptions", { deep: true })
    pagerOptionsHandles() {
        this.loadSupervisorArchiveList();
    }

    created() {
        if (this.selectedContractorSenderListForFilter == null || this.selectedContractorSenderListForFilter == undefined) {
            this.selectedContractorSenderListForFilter = [];
        }
        if (this.selectedContractorCarrierListForFilter == null || this.selectedContractorCarrierListForFilter == undefined) {
            this.selectedContractorCarrierListForFilter = [] as ContractorSearchItemModel[];
        }
    }

    getSupervisorProfitItemStyle(supervisorProfit: number) {
        if (supervisorProfit > 0) {
            return "teal--text text--accent-4";
        }
        else if (supervisorProfit < 0) {
            return "red--text";
        }
        else {
            return "";
        }
    }

    getSupervisorItemStyle(item: SupervisorRestModel) {
        if (item.isStorno) {
            return "red--text text-decoration-line-through";
        }
        return "";
    }

    twoWayPlaceSearchingCheckboxChanged() {
        if (this.twoWayPlaceSearching == true) {
            this.searchPlaceType = 1;
            this.searchUnloadingPlaceType = 2;
        }
        else {
            this.searchPlaceType = 0;
        }
    }

    formatDateTime(time: any) {
        if (time != null) {
            return dayjs(time).format("YYYY-MM-DD HH:mm");
        }
    }

    clearLoadInPlaceClick() {
        this.searchLoadInPlace = "";
        this.loadSupervisorArchiveList();
    }

    clearLoadOutPlaceClick() {
        this.searchLoadOutPlace = "";
        this.loadSupervisorArchiveList();
    }

    searchClick() {
        this.loadSupervisorArchiveList();
    }

    clearSearchClick() {
        this.searchText = "";
        this.loadSupervisorArchiveList();
    }

    clearSearchPlaceNameClick() {
        this.searchPlaceName = "";
        this.loadSupervisorArchiveList();
    }

    clearSearchUnloadingPlaceNameClick() {
        this.searchUnloadingPlaceName = "";
        this.loadSupervisorArchiveList();
    }

    clearSearchPlaceCountryClick() {
        this.searchPlaceCountry = "";
        this.loadSupervisorArchiveList();
    }

    clearSearchUnloadingPlaceCountryClick() {
        this.searchUnloadingPlaceCountry = "";
        this.loadSupervisorArchiveList();
    }

    clearSearchPlacePostalCodeClick() {
        this.searchPlacePostalCode = "";
        this.loadSupervisorArchiveList();
    }

    clearSearchUnloadingPlacePostalCodeClick() {
        this.searchUnloadingPlacePostalCode = "";
        this.loadSupervisorArchiveList();
    }

    clearSearchPlaceCityClick() {
        this.searchPlaceCity = "";
        this.loadSupervisorArchiveList();
    }

    clearSearchUnloadingPlaceCityClick() {
        this.searchUnloadingPlaceCity = "";
        this.loadSupervisorArchiveList();
    }

    clearSearchPlaceStreetClick() {
        this.searchPlaceStreet = "";
        this.loadSupervisorArchiveList();
    }

    clearSearchUnloadingPlaceStreetClick() {
        this.searchUnloadingPlaceStreet = "";
        this.loadSupervisorArchiveList();
    }

    clearSearchContractorSenderClick() {
        this.selectedContractorSenderListForFilter = [] as ContractorSearchItemModel[];
        this.loadSupervisorArchiveList();
    }

    clearSearchContractorCarrierClick() {
        this.selectedContractorCarrierListForFilter = [] as ContractorSearchItemModel[];
        this.loadSupervisorArchiveList();
    }

    clearSearchRegistrationNumberClick() {
        this.searchRegistrationNumber = "";
        this.loadSupervisorArchiveList();
    }

    clearSearchOrderIdClick() {
        this.searchOrderId = "";
        this.loadSupervisorArchiveList();
    }

    clearSearchDriver1NameClick() {
        this.searchDriver1Name = "";
        this.loadSupervisorArchiveList();
    }

    clearSearchDriver2NameClick() {
        this.searchDriver2Name = "";
        this.loadSupervisorArchiveList();
    }

    clearSearchCommentClick() {
        this.searchComment = "";
        this.loadSupervisorArchiveList();
    }

    clearDateTimePicker(clearableValue: string) {
        if (clearableValue == "searchPlanningDateStartDateValue") {
            this.searchPlanningDateStartDate = "";
        }
        if (clearableValue == "searchPlanningDateEndDateValue") {
            this.searchPlanningDateEndDate = "";
        }
        if (clearableValue == "searchRealDateStartDateValue") {
            this.searchRealDateStartDate = "";
        }
        if (clearableValue == "searchRealDateEndDateValue") {
            this.searchRealDateEndDate = "";
        }
        if (clearableValue == "searchUnloadingPlanningDateStartDateValue") {
            this.searchUnloadingPlanningDateStartDate = "";
        }
        if (clearableValue == "searchUnloadingPlanningDateEndDateValue") {
            this.searchUnloadingPlanningDateEndDate = "";
        }
        if (clearableValue == "searchUnloadingRealDateStartDateValue") {
            this.searchUnloadingRealDateStartDate = "";
        }
        if (clearableValue == "searchUnloadingRealDateEndDateValue") {
            this.searchUnloadingRealDateEndDate = "";
        }
        this.loadSupervisorArchiveList();
    }

    clearAllComplexFilters() {
        this.searchPlaceType = 0
        this.searchPlaceName = "";
        this.searchUnloadingPlaceName = "";
        this.searchPlaceCountry = "";
        this.searchUnloadingPlaceCountry = "";
        this.searchPlacePostalCode = "";
        this.searchUnloadingPlacePostalCode = "";
        this.searchPlaceCity = "";
        this.searchUnloadingPlaceCity = "";
        this.searchPlaceStreet = "";
        this.searchUnloadingPlaceStreet = "";
        this.searchCustomerIdcs = [] as number[];
        this.searchShippingCompanyIdcs = [];
        this.searchRegistrationNumber = "";
        this.searchOrderId = "";
        this.localStorageApplicationStateData.supervisorListSelectedDepartments = [] as number[];
        this.searchVehicleTypeList = [] as string[];
        this.searchDriver1Name = "";
        this.searchDriver2Name = "";
        this.searchComment = "";
        this.searchIsADR = false;
        this.searchTaillift = false;
        this.searchExtinguisher = false;
        this.searchNoCostDocument = false;
        this.searchNoIncomeDocument = false;
        this.selectedContractorCarrierListForFilter = [] as ContractorSearchItemModel[];
        this.selectedContractorSenderListForFilter = [] as ContractorSearchItemModel[];
        this.searchPlanningDateStartDate = "";
        this.searchPlanningDateEndDate = "";
        this.searchRealDateStartDate = "";
        this.searchRealDateEndDate = "";
        this.searchUnloadingPlanningDateStartDate = "";
        this.searchUnloadingPlanningDateEndDate = "";
        this.searchUnloadingRealDateStartDate = "";
        this.searchUnloadingRealDateEndDate = "";
        this.searchIsStorno = 0;

        this.loadSupervisorArchiveList();
    }

    editSupervisorRowClick(supervisor: SupervisorRestModel) {
        if (window.getSelection()?.toString()) {
            return
        }
        this.backToSupervisorArchiveListForSettlement = false;
        this.$router.push({ path: "/supervisor/edit/" + supervisor.id });
    }

    editSupervisorClick(supervisor: SupervisorRestModel) {
        this.backToSupervisorArchiveListForSettlement = false;
        return "/supervisor/edit/" + supervisor.id;
    }

    showSupervisorAttachmentClick(supervisorId: number) {
        return "/supervisor/attachment-list/" + supervisorId;
    }

    async restoreSupervisorClick(item: SupervisorRestModel) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: `${this.$t("supervisor.restore")}: "${item.orderId}"`,
                message: `${this.$t("supervisor.restoreMessage")}`,
                links: [],
                options: { buttonColor: "primary", layoutColor: "primary" },
                buttonType: "yes/no"
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.supervisor.changeSupervisorArchiveValue({ supervisorId: item.id, isArchived: false });
                    this.loadSupervisorArchiveList();
                }
            });
    }

    loadSupervisorArchiveList() {
        if (this.selectedContractorSenderListForFilter != undefined && this.selectedContractorSenderListForFilter.length > 0) {
            this.searchCustomerIdcs = this.selectedContractorSenderListForFilter.map(x => x.value);
        }
        else {
            this.searchCustomerIdcs = [];
        }
        if (this.selectedContractorCarrierListForFilter != undefined && this.selectedContractorCarrierListForFilter.length > 0) {
            this.searchShippingCompanyIdcs = this.selectedContractorCarrierListForFilter.map(x => x.value);
        }
        else {
            this.searchShippingCompanyIdcs = [];
        }

        this.$store.direct.dispatch.supervisor.loadSupervisorArchiveList({
            requestModel: this.prepareSupervisorArchiveListRequestModel()
        });
    }

    async duplicateSupervisorClick(supervisor: SupervisorRestModel, fullDuplication: boolean) {
        await this.$router.push({ path: "/supervisor/duplicate/" + supervisor.id + "/" + fullDuplication });
    }

    openSupervisorReport() {
        return "/supervisor/report";
    }

    hideFiltersMenuClick() {
        this.showFiltersMenu = false
    }

    createXlsReportClick() {
        this.$store.direct.dispatch.supervisor.downloadXlsReportSupervisorArchiveList({
            requestModel: this.prepareSupervisorArchiveListRequestModel()
        });
    }

    createDetailedXlsReportClick(detailed: boolean) {
        this.$store.direct.dispatch.supervisor.downloadDetailedXlsReportSupervisorArchiveList({
            requestModel: this.prepareSupervisorArchiveListRequestModel()
        });
    }

    createXlsReportForCustomerClick(detailed: boolean) {
        this.$store.direct.dispatch.supervisor.downloadXlsReportSupervisorArchiveListForCustomer({
            requestModel: this.prepareSupervisorArchiveListRequestModel()
        });
    }

    prepareSupervisorArchiveListRequestModel(): SupervisorArchiveListRequestModel {
        return {
            page: this.pagerOptions.page,
            pageSize: this.pagerOptions.itemsPerPage,
            searchLoadInPlace: this.searchLoadInPlace,
            searchLoadOutPlace: this.searchLoadOutPlace,
            searchText: this.searchText,
            searchPlaceType: this.searchPlaceType,
            searchUnloadingPlaceType: this.searchUnloadingPlaceType,
            searchPlaceName: this.searchPlaceName,
            searchUnloadingPlaceName: this.searchUnloadingPlaceName,
            searchPlaceCountry: this.searchPlaceCountry,
            searchUnloadingPlaceCountry: this.searchUnloadingPlaceCountry,
            searchPlacePostalCode: this.searchPlacePostalCode,
            searchUnloadingPlacePostalCode: this.searchUnloadingPlacePostalCode,
            searchPlaceCity: this.searchPlaceCity,
            searchUnloadingPlaceCity: this.searchUnloadingPlaceCity,
            searchPlaceStreet: this.searchPlaceStreet,
            searchUnloadingPlaceStreet: this.searchUnloadingPlaceStreet,
            searchCustomerIdcs: this.searchCustomerIdcs,
            searchShippingCompanyIdcs: this.searchShippingCompanyIdcs,
            searchRegistrationNumber: this.searchRegistrationNumber,
            searchNoCostDocument: this.searchNoCostDocument,
            searchNoIncomeDocument: this.searchNoIncomeDocument,
            searchOrderId: this.searchOrderId,
            searchPlanningDateStartDate: this.searchPlanningDateStartDate,
            searchPlanningDateEndDate: this.searchPlanningDateEndDate,
            searchRealDateStartDate: this.searchRealDateStartDate,
            searchRealDateEndDate: this.searchRealDateEndDate,
            searchUnloadingPlanningDateStartDate: this.searchUnloadingPlanningDateStartDate,
            searchUnloadingPlanningDateEndDate: this.searchUnloadingPlanningDateEndDate,
            searchUnloadingRealDateStartDate: this.searchUnloadingRealDateStartDate,
            searchUnloadingRealDateEndDate: this.searchUnloadingRealDateEndDate,
            searchDepartments: this.localStorageApplicationStateData.supervisorListSelectedDepartments,
            searchVehicleTypes: this.searchVehicleTypeList,
            searchDriver1Name: this.searchDriver1Name,
            searchDriver2Name: this.searchDriver2Name,
            searchComment: this.searchComment,
            searchIsADR: this.searchIsADR,
            searchTaillift: this.searchTaillift,
            searchExtinguisher: this.searchExtinguisher,
            searchIsStorno: this.searchIsStorno,
            twoWayPlaceSearching: this.twoWayPlaceSearching,
        } as SupervisorArchiveListRequestModel
    }
}
