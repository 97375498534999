import VForm from "@/models/v-form";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { stringMaxLength } from "../../../assets/validation/common";
import { BranchEnum } from "../../../models/emums/branchEnum";
import { Roles } from "../../../models/emums/roles";
import ListItemNumberModel from "../../../models/listItemNumberModel";
import ContractorBankAccountRestModel from "../../../models/rest/contractor/contractorBankAccountRestModel";
import ContractorRestModel from "../../../models/rest/contractor/contractorRestModel";
import CountryRestModel from "../../../models/rest/register/countryRestModel";
import CurrencyRestModel from "../../../models/rest/register/currencyRestModel";

@Component
export default class FinanceTab extends Vue {
    $refs!: {
        form: VForm;
    };

    validation = {
        tin: [(value: string) => {
            if (this.contractor.isPrivatePerson && this.contractor.typeIsClient && !this.contractor.typeIsCarrier && !this.contractor.typeIsPartner && !this.contractor.typeIsOther) {
                return true;
            }
            return !!value || this.$t("common.validationErrorRequired");
        }],
        specialInfo: [stringMaxLength(this.$i18n, this.$t("contractor.accountingInfo"), 2048)],
    };

    bankAccountListHeaders = [
        { text: this.$t("contractor.accountNumber"), align: "start", value: "accountNumber" },
        { text: this.$t("contractor.iban"), align: "start", value: "iban" },
        { text: this.$t("contractor.swift"), align: "start", value: "swift" },
        { text: this.$t("contractor.bankName"), align: "start", value: "bankName" },
        { text: this.$t("contractor.accountCountry"), align: "start", value: "countryCode" },
        { text: this.$t("contractor.accountCurrency"), align: "start", value: "currencyCode" },
        { text: this.$t("contractor.vatAccount"), align: "center", value: "isVatAccount" },
        { text: this.$t("contractor.bankAccountPurpose"), align: "center", value: "bankAccountPurposeTypeId" },
        { text: this.$t("contractor.isDefault"), align: "center", value: "isDefault" },
        { text: this.$t("contractor.actions"), align: "right", value: "actions", width: "1%" }
    ];

    get contractor(): ContractorRestModel {
        const contractor = this.$store.direct.state.contractor.contractor;
        if (this.isContractorNew == true && Object.keys(contractor).length > 0) {
            contractor.paymentTermPurchase = 60;
            contractor.paymentTermSale = 30;
            contractor.purchaseValueCurrency = "EUR";
            contractor.saleValueCurrency = "EUR";
        }
        return contractor;
    }

    get isContractorNew(): boolean {
        return this.$store.direct.state.contractor.isContractorNew;
    }

    get allowContractorEdit(): boolean {
        return this.$store.direct.state.contractor.allowContractorEdit;
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get tinLoading(): boolean {
        return this.$store.direct.state.contractor.contractorImportLoading;
    }

    get countryList(): CountryRestModel[] {
        return this.$store.direct.state.contractor.contractorAssets.countries;
    }

    get currentUserRoles(): string[] {
        return this.$store.direct.state.auth.userRoles;
    }

    get userIsAdminOrCrmAdmin(): boolean {
        return this.currentUserRoles.includes(Roles.Admin) || this.currentUserRoles.includes(Roles.CrmAdmin);
    }

    get userIsAdminOrAccountant(): boolean {
        return this.currentUserRoles.includes(Roles.Admin) || this.currentUserRoles.includes(Roles.Accountant) || this.currentUserRoles.includes(Roles.AccountantAdmin);
    }

    get bankAccountList(): ContractorBankAccountRestModel[] {
        return this.$store.direct.state.contractor.bankAccountList;
    }

    set bankAccountList(val: ContractorBankAccountRestModel[]) {
        this.$store.direct.commit.contractor.setBankAccountList(val);
    }

    get currencies(): CurrencyRestModel[] {
        return this.$store.direct.state.contractor.contractorAssets.currencies;
    }

    get bankAccountPurposeTypeList(): ListItemNumberModel[] {
        return this.$store.direct.state.contractor.bankAccountPurposeTypeList;
    }

    get languageList(): CountryRestModel[] {
        return this.countryList?.filter((x: CountryRestModel) => {
            return x.code == "PL" || x.code == "EN" || x.code == "DE";
        });
    }

    get marwayBankAccounts(): ContractorBankAccountRestModel[] {
        const marwayDeBankAccounts = this.$store.direct.state.settlementCommon.settlementAssets.marwayDeBankAccounts;
        const marwayPlBankAccounts = this.$store.direct.state.settlementCommon.settlementAssets.marwayPlBankAccounts;

        if (marwayDeBankAccounts != undefined && marwayPlBankAccounts != undefined) {
            let bankAccountList = [] as ContractorBankAccountRestModel[];
            if (this.contractor.branchId == BranchEnum.DE) {
                bankAccountList = marwayDeBankAccounts;
            }
            else if (this.contractor.branchId == BranchEnum.PL) {
                bankAccountList = marwayPlBankAccounts;
            }
            else {
                bankAccountList = marwayDeBankAccounts.concat(marwayPlBankAccounts);
            }
            return bankAccountList.filter((x: ContractorBankAccountRestModel) => {
                return x.bankAccountPurposeTypeId == 0 || x.bankAccountPurposeTypeId == 2;
            });
        }
        return [] as ContractorBankAccountRestModel[];
    }

    formatBankAccountPurposeTypeIdToBankAccountPurposeName(bankAccountPurposeTypeId: number) {
        if (this.bankAccountPurposeTypeList != undefined) {
            return this.bankAccountPurposeTypeList.find(x => x.value == bankAccountPurposeTypeId)?.text;
        }
    }

    allowNumbersOnly(evt: any) {
        return this.$store.direct.dispatch.settlementCommon.allowNumbersOnly(evt);
    }

    async bankAccountCreateClick() {
        if (this.isContractorNew == false) {
            this.$store.direct.commit.contractor.setShowBankAccountDialog(true);
        }
        else {
            this.$store.direct.dispatch.confirm.openConfirmDialog(
                {
                    showConfirmDialog: true,
                    title: this.$t("contractor.savingContractorIsRequired").toString(),
                    message: this.$t("contractor.saveContractorIsRequiredForThisAction").toString(),
                    links: [],
                    options: { buttonColor: "error", layoutColor: "error" },
                    buttonType: "ok"
                });
        }
    }

    async bankAccountEditClick(model: ContractorBankAccountRestModel) {
        await this.$store.direct.dispatch.contractor.loadBankAccount({ id: model.id });
    }

    async importContractorClick(): Promise<void> {
        await this.$store.direct.dispatch.contractor.importContractor();
    }

    validate(): number {
        let errorCount = 0;
        this.$refs.form.validate();
        this.$refs.form.inputs.forEach((input: any) => {
            if (input.validate() === false) ++errorCount;
        });
        return errorCount;
    }

    destroyed() {
        this.bankAccountList = [] as ContractorBankAccountRestModel[];
    }
}
