import Vue from "vue";
import { Component } from "vue-property-decorator";
import ListItemNumberModel from "../../../models/listItemNumberModel";
import ListItemStringModel from "../../../models/listItemStringModel";
import SupervisorProcedureRestModel from "../../../models/rest/supervisor/supervisorProcedureRestModel";
import VForm from "../../../models/v-form";

@Component
export default class ProcedureDialog extends Vue {
    requiredRule = (value: string) => !!value || this.$t("common.validationErrorRequired");

    validation = {
        assignedToUserAsManagerId: [(value: string) => !!value || this.$t("common.validationErrorRequired")],
        vin: [(value: string) => !!value || this.$t("common.validationErrorRequired")],
        files: [
            (files: any[]) => {
                return !files || !files.some((file: any) => file.size > 20_971_520) || 'Attachment size should be less than 20 MB!';
            },
            (files: any[]) => {
                return (this.procedure.id != undefined && this.procedure.id != 0) || (files && files.length > 0) || 'You must upload at least one file!';
            },
        ]

    };

    procedureFileListHeaders = [
        {
            text: this.$t("contractor.file"),
            align: "start",
            value: "text"
        },
    ];

    get procedureFileList(): ListItemNumberModel[] {
        return this.$store.direct.state.supervisor.procedureFileList;
    }

    get procedureDialogAttachments(): any[] {
        return this.$store.direct.state.supervisor.procedureDialogAttachments;
    }

    set procedureDialogAttachments(value: any[]) {
        this.$store.direct.commit.supervisor.setProcedureDialogAttachments(value);
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get showDialog(): boolean {
        return this.$store.direct.state.supervisor.showProcedureDialog;
    }

    get currentUserRoles(): string[] {
        return this.$store.direct.state.auth.userRoles;
    }

    get userListForSupervisorProcedure(): ListItemStringModel[] {
        return this.$store.direct.state.contractor.contractorAssets.userListForSupervisorProcedure;
    }

    get procedure(): SupervisorProcedureRestModel {
        return this.$store.direct.state.supervisor.procedure;
    }

    get currentUserId(): string {
        return this.$store.direct.state.auth.currentUserId;
    }

    get currentuserIsAssignedAsManager(): boolean {
        return this.procedure.assignedToUserAsManagerId == this.currentUserId;
    }

    updated() {
        this.$nextTick(function () {
            if (this.$refs.form) (this.$refs.form as VForm).resetValidation();
        });
    }

    async procedureFileDownloadClick(procedureFile: ListItemNumberModel) {
        await this.$store.direct.dispatch.supervisor.downloadProcedureFile({ id: procedureFile.value });
    }


    async saveClick(): Promise<void> {
        if (this.procedure.id == undefined) {
            this.procedure.id = 0;
        }
        if (this.procedure.isApprovedByManager == undefined) {
            this.procedure.isApprovedByManager = false;
        }
        if (this.procedure.isApprovedByDisponent == undefined) {
            this.procedure.isApprovedByDisponent = false;
        }

        if ((this.$refs.form as VForm).validate()) {
            await this.$store.direct.dispatch.supervisor.saveProcedure({ files: this.procedureDialogAttachments, procedureId: this.procedure.id, vin: this.procedure.vin, isApprovedByManager: this.procedure.isApprovedByManager, isApprovedByDisponent: this.procedure.isApprovedByDisponent, assignedToUserAsManagerId: this.procedure.assignedToUserAsManagerId });
            await this.$store.direct.dispatch.supervisor.isConfirmedProcedureDialog();
            this.clearProcedureandProcedureFileList();
        }
    }

    closeClick(): void {
        this.$store.direct.dispatch.supervisor.isNotConfirmedProcedureDialog();
        this.clearProcedureandProcedureFileList();
    }

    clearProcedureandProcedureFileList() {
        this.$store.direct.commit.supervisor.setProcedure({} as SupervisorProcedureRestModel);
        this.$store.direct.commit.supervisor.setProcedureFileList([] as ListItemNumberModel[]);
    }
}
