import dayjs from "dayjs";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import i18n from "../../assets/i18n";
import ConfirmDialog from "../../layouts/confirmDialog/confirmDialog.vue";
import { ContractorTypes } from "../../models/emums/contractorTypes";
import { Roles } from "../../models/emums/roles";
import ListItemNumberModel from "../../models/listItemNumberModel";
import ListItemStringModel from "../../models/listItemStringModel";
import LocalStorageApplicationStateDataModel from "../../models/localStorageApplicationStateDataModel";
import ContractorAssetsRestModel from "../../models/rest/contractor/contractorAssetsRestModel";
import ContractorDetailsForSettlementRestModel from "../../models/rest/contractor/contractorDetailsForSettlementRestModel";
import ContractorSearchItemModel from "../../models/rest/contractor/contractorSearchItemModel";
import DepartmentItemRestModel from "../../models/rest/register/departmentItemRestModel";
import SettlementIncomeDocumentItemRestModel from "../../models/rest/settlement/income-document/settlementIncomeDocumentItemRestModel";
import SettlementIncomeDocumentRestModel from "../../models/rest/settlement/income-document/settlementIncomeDocumentRestModel";
import SupervisorAmountSummaryRestModel from "../../models/rest/supervisor/supervisorAmountSummaryRestModel";
import SupervisorArchiveListForSettlementRequestModel from "../../models/rest/supervisor/supervisorArchiveListForSettlementRequestModel";
import SupervisorArchiveListItemForSettlementRestModel from "../../models/rest/supervisor/supervisorArchiveListItemForSettlementRestModel";
import SupervisorForSettlementRestModel from "../../models/rest/supervisor/supervisorForSettlementRestModel";
import SupervisorRestModel from "../../models/rest/supervisor/supervisorRestModel";
import SupervisorAmountSummaryRow from "./supervisor-amount-summary-row.vue";
import SettlementDialog from "./dialog/settlement-dialog.vue";
import { BranchEnum } from "../../models/emums/branchEnum";


@Component({
    components: {
        "confirm-dialog": ConfirmDialog,
        "settlement-dialog": SettlementDialog,
        "supervisor-amount-summary-row": SupervisorAmountSummaryRow,
    }
})

export default class SupervisorArchiveListForSettlement extends Vue {
    planningDateStartDateLabel = this.$t("supervisor.planningStartDate");
    planningDateEndDateLabel = this.$t("supervisor.planningEndDate");
    realDateStartDateLabel = this.$t("supervisor.doneStartDate");
    realDateEndDateLabel = this.$t("supervisor.doneEndDate");
    createdAtStartDateLabel = this.$t("supervisor.createdAtStartDate");
    createdAtEndDateLabel = this.$t("supervisor.createdAtEndDate");
    realLoadInDateStartDateLabel = this.$t("supervisor.realLoadInDateStartDate");
    realLoadInDateEndDateLabel = this.$t("supervisor.realLoadInDateEndDate");
    realLoadOutDateStartDateLabel = this.$t("supervisor.realLoadOutDateStartDate");
    realLoadOutDateEndDateLabel = this.$t("supervisor.realLoadOutDateEndDate");
    showFiltersMenu = false;
    contractorSenderSearch = "";
    contractorPayerSearch = "";
    contractorCarrierSearch = "";
    supervisorListForCreateIncomeDocument = [] as SupervisorArchiveListItemForSettlementRestModel[];
    supervisorListForCreateIncomeDocumentBranchId = 0;
    supervisorListForCreateIncomeDocumentCustomerCompanyId = 0;

    supervisorArchiveListHeaders = [
        {
            align: "start",
            value: "checkbox",
            width: "1%",
            sortable: false,
            visible: true
        },
        {
            text: "CMR",
            align: "center",
            value: "hasCmr",
            sortable: true,
            visible: true
        },
        {
            text: this.$t("supervisor.isWithoutInvoicing"),
            align: "center",
            value: "isWithoutInvoicing",
            sortable: true,
            width: "1%",
            visible: this.localStorageApplicationStateData.supervisorArchiveListForSettlementIsWithoutInvoicingColumn
        },
        {
            text: this.$t("supervisor.isServiceCompleted"),
            align: "center",
            value: "isServiceCompleted",
            sortable: true,
            width: "1%",
            visible: this.localStorageApplicationStateData.supervisorArchiveListForSettlementIsServiceCompletedColumn
        },
        {
            text: this.$t("contractor.department"),
            align: "start",
            value: "departmentId",
            sortable: true,
            visible: true
        },
        {
            text: this.$t("supervisor.orderId"),
            align: "start",
            value: "orderId",
            sortable: true,
            visible: true
        },
        {
            text: this.$t("common.createdAt"),
            align: "start",
            value: "createdAt",
            sortable: true,
            visible: true
        },
        {
            text: this.$t("supervisor.realLoadingDate"),
            align: "start",
            value: "realLoadingDate",
            sortable: true,
            visible: true
        },
        {
            text: this.$t("supervisor.realUnloadingDate"),
            align: "start",
            value: "realUnloadingDate",
            sortable: true,
            visible: true
        },
        {
            text: this.$t("supervisor.loadInCountry"),
            align: "start",
            value: "loadInCountry",
            sortable: true,
            visible: true
        },
        {
            text: this.$t("supervisor.loadOutCountry"),
            align: "start",
            value: "loadOutCountry",
            sortable: true,
            visible: true
        },
        {
            text: this.$t("supervisor.route"),
            align: "start",
            value: "route",
            sortable: false,
            visible: true
        },
        {
            text: this.$t("supervisor.customer"),
            align: "start",
            value: "customer",
            sortable: true,
            visible: true
        },
        {
            text: this.$t("supervisor.customerOrderId"),
            align: "start",
            value: "customerOrderId",
            sortable: true,
            visible: true
        },
        {
            text: this.$t("supervisor.incomeDocumentList"),
            align: "start",
            value: "incomeDocumentListString",
            sortable: true,
            visible: true
        },
        {
            text: this.$t("supervisor.shippingCompany"),
            align: "start",
            value: "shippingCompany",
            sortable: true,
            visible: true
        },
        {
            text: this.$t("supervisor.costDocumentList"),
            align: "start",
            value: "costDocumentListString",
            sortable: true,
            visible: true
        },
        {
            text: this.$t("supervisor.customerFreight"),
            align: "start",
            value: "customerFreight",
            sortable: true,
            visible: true
        },
        {
            text: this.$t("supervisor.forwarderFreight"),
            align: "start",
            value: "forwarderFreight",
            sortable: true,
            visible: true
        },
        {
            text: this.$t("supervisor.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
            visible: true
        }
    ];

    get localStorageApplicationStateData(): LocalStorageApplicationStateDataModel {
        return this.$store.direct.state.common.localStorageApplicationStateData;
    }

    get supervisorArchiveListHeadersComputedHeaders(): any[] {
        return this.supervisorArchiveListHeaders.filter(x => x.visible == true);
    }

    get supervisorArchiveListAmountSummaryList(): SupervisorAmountSummaryRestModel[] {
        let amountSummary = this.$store.direct.state.supervisorForSettlement.supervisorArchiveListAmountSummaryList;

        if (amountSummary == undefined) {
            amountSummary = [] as SupervisorAmountSummaryRestModel[];
        }
        return amountSummary;
    }

    @Watch("localStorageApplicationStateData", { deep: true })
    localStorageApplicationStateDataHandles() {
        this.$store.direct.dispatch.common.updateLocalStorageApplicationStateData();

        this.supervisorArchiveListHeaders.forEach((x: any) => {
            if (x.value == "isWithoutInvoicing") {
                x.visible = this.localStorageApplicationStateData.supervisorArchiveListForSettlementIsWithoutInvoicingColumn;
            }
            if (x.value == "isServiceCompleted") {
                x.visible = this.localStorageApplicationStateData.supervisorArchiveListForSettlementIsServiceCompletedColumn;
            }
        })
    }

    get hasOrHasNotOrAllTypeList(): ListItemNumberModel[] {
        return this.$store.direct.state.common.hasOrHasNotOrAllTypeList;
    }

    get orderTypesForFilters(): ListItemNumberModel[] {
        const orderTypes = [];
        this.$store.direct.state.supervisor.supervisorPlaceOrderType.forEach((val: ListItemNumberModel) =>
            orderTypes.push(Object.assign({}, val))
        );
        orderTypes.push({ value: 0, text: "Not set" });

        return orderTypes;
    }

    get vehicleTypes(): ListItemStringModel[] {
        return this.$store.direct.state.supervisor.vehicleTypes;
    }

    get departments(): ListItemNumberModel[] {
        const departments = this.$store.direct.state.contractor.contractorAssets.departments;
        if (departments == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        return departments;
    }

    get searchLoadInPlace(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchLoadInPlace;
    }

    set searchLoadInPlace(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchLoadInPlace(value);
    }

    get searchLoadOutPlace(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchLoadOutPlace;
    }

    set searchLoadOutPlace(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchLoadOutPlace(value);
    }

    get searchPlaceType(): number {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchPlaceType;
    }

    set searchPlaceType(value: number) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchPlaceType(value);
    }

    get searchUnloadingPlaceType(): number {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchUnloadingPlaceType;
    }

    set searchUnloadingPlaceType(value: number) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchUnloadingPlaceType(value);
    }

    get twoWayPlaceSearching(): boolean {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListTwoWayPlaceSearching;
    }

    set twoWayPlaceSearching(value: boolean) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListTwoWayPlaceSearching(value);
    }

    get showSupervisorsAlsoWithoutPurchaseAndSaleValue(): boolean {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListShowSupervisorsAlsoWithoutPurchaseAndSaleValue;
    }

    set showSupervisorsAlsoWithoutPurchaseAndSaleValue(value: boolean) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListShowSupervisorsAlsoWithoutPurchaseAndSaleValue(value);
    }

    get searchPlaceName(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchPlaceName;
    }

    set searchPlaceName(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchPlaceName(value);
    }

    get searchUnloadingPlaceName(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchUnloadingPlaceName;
    }

    set searchUnloadingPlaceName(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchUnloadingPlaceName(value);
    }

    get searchPlaceCountry(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchPlaceCountry;
    }

    set searchPlaceCountry(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchPlaceCountry(value);
    }

    get searchUnloadingPlaceCountry(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchUnloadingPlaceCountry;
    }

    set searchUnloadingPlaceCountry(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchUnloadingPlaceCountry(value);
    }

    get searchPlacePostalCode(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchPlacePostalCode;
    }

    set searchPlacePostalCode(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchPlacePostalCode(value);
    }

    get searchUnloadingPlacePostalCode(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchUnloadingPlacePostalCode;
    }

    set searchUnloadingPlacePostalCode(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchUnloadingPlacePostalCode(value);
    }

    get searchPlaceCity(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchPlaceCity;
    }

    set searchPlaceCity(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchPlaceCity(value);
    }

    get searchUnloadingPlaceCity(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchUnloadingPlaceCity;
    }

    set searchUnloadingPlaceCity(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchUnloadingPlaceCity(value);
    }

    get searchPlaceStreet(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchPlaceStreet;
    }

    set searchPlaceStreet(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchPlaceStreet(value);
    }

    get searchUnloadingPlaceStreet(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchUnloadingPlaceStreet;
    }

    set searchUnloadingPlaceStreet(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchUnloadingPlaceStreet(value);
    }

    get searchCustomerIdcs(): number[] {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchCustomerIdcs;
    }

    set searchCustomerIdcs(value: number[]) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchCustomerIdcs(value);
    }

    get searchPayerIdcs(): number[] {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchPayerIdcs;
    }

    set searchPayerIdcs(value: number[]) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchPayerIdcs(value);
    }

    get searchShippingCompanyIdcs(): number[] {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchShippingCompanyIdcs;
    }

    set searchShippingCompanyIdcs(value: number[]) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchShippingCompanyIdcs(value);
    }

    get searchRegistrationNumber(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchRegistrationNumber;
    }

    set searchRegistrationNumber(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchRegistrationNumber(value);
    }

    get searchOrderId(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchOrderId;
    }

    set searchOrderId(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchOrderId(value);
    }

    get searchCustomerOrderId(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchCustomerOrderId;
    }

    set searchCustomerOrderId(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchCustomerOrderId(value);
    }

    get searchVehicleTypeList(): string[] {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchVehicleTypeList;
    }

    set searchVehicleTypeList(value: string[]) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchVehicleTypeList(value);
    }

    get searchDriver1Name(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchRDriver1Name;
    }

    set searchDriver1Name(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchDriver1Name(value);
    }

    get searchDriver2Name(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchDriver2Name;
    }

    set searchDriver2Name(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchDriver2Name(value);
    }

    get searchComment(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchComment;
    }

    set searchComment(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchComment(value);
    }

    get searchIsADR(): boolean {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchIsADR;
    }

    set searchIsADR(value: boolean) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchIsADR(value);
    }

    get searchTaillift(): boolean {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchTaillift;
    }

    set searchTaillift(value: boolean) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchTaillift(value);
    }

    get searchExtinguisher(): boolean {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchExtinguisher;
    }

    set searchExtinguisher(value: boolean) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchExtinguisher(value);
    }

    get searchHasCmrFile(): boolean {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchHasCmrFile;
    }

    set searchHasCmrFile(value: boolean) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchHasCmrFile(value);
    }

    get searchHasCostDocument(): number {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchHasCostDocument;
    }

    set searchHasCostDocument(value: number) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchHasCostDocument(value);
    }

    get searchHasIncomeDocument(): number {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchHasIncomeDocument;
    }

    set searchHasIncomeDocument(value: number) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchHasIncomeDocument(value);
    }

    get searchCreatedAtStartDate(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchCreatedAtStartDate;
    }

    set searchCreatedAtStartDate(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchCreatedAtStartDate(value);
    }

    get searchCreatedAtEndDate(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchCreatedAtEndDate;
    }

    set searchCreatedAtEndDate(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchCreatedAtEndDate(value);
    }

    get searchRealLoadInDateStartDate(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchRealLoadInDateStartDate;
    }

    set searchRealLoadInDateStartDate(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchRealLoadInDateStartDate(value);
    }

    get searchRealLoadInDateEndDate(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchRealLoadInDateEndDate;
    }

    set searchRealLoadInDateEndDate(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchRealLoadInDateEndDate(value);
    }

    get searchRealLoadOutDateStartDate(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchRealLoadOutDateStartDate;
    }

    set searchRealLoadOutDateStartDate(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchRealLoadOutDateStartDate(value);
    }

    get searchRealLoadOutDateEndDate(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchRealLoadOutDateEndDate;
    }

    set searchRealLoadOutDateEndDate(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchRealLoadOutDateEndDate(value);
    }

    get searchPlanningDateStartDate(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchPlanningDateStartDate;
    }

    set searchPlanningDateStartDate(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchPlanningDateStartDate(value);
    }

    get searchUnloadingPlanningDateStartDate(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchUnloadingPlanningDateStartDate;
    }

    set searchUnloadingPlanningDateStartDate(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchUnloadingPlanningDateStartDate(value);
    }

    get searchPlanningDateEndDate(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchPlanningDateEndDate;
    }

    set searchPlanningDateEndDate(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchPlanningDateEndDate(value);
    }

    get searchUnloadingPlanningDateEndDate(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchUnloadingPlanningDateEndDate;
    }

    set searchUnloadingPlanningDateEndDate(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchUnloadingPlanningDateEndDate(value);
    }

    get searchRealDateStartDate(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchRealDateStartDate;
    }

    set searchRealDateStartDate(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchRealDateStartDate(value);
    }

    get searchUnloadingRealDateStartDate(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchUnloadingRealDateStartDate;
    }

    set searchUnloadingRealDateStartDate(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchUnloadingRealDateStartDate(value);
    }

    get searchRealDateEndDate(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchRealDateEndDate;
    }

    set searchRealDateEndDate(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchRealDateEndDate(value);
    }

    get searchUnloadingRealDateEndDate(): string {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchUnloadingRealDateEndDate;
    }

    set searchUnloadingRealDateEndDate(value: string) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchUnloadingRealDateEndDate(value);
    }

    get searchBranchId(): number {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchBranchId;
    }

    set searchBranchId(value: number) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchBranchId(value);
    }

    get searchIsStorno(): number {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListSearchIsStorno;
    }

    set searchIsStorno(value: number) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListSearchIsStorno(value);
    }

    get pagerOptions(): any {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListPagerOptions;
    }

    set pagerOptions(value: any) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListPagerOptions(value);
    }

    get footerPropsForSettlement(): any {
        return this.$store.direct.state.settlementCommon.footerPropsForSettlement;
    }

    get supervisorArchiveListLoading(): boolean {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListLoading;
    }

    get supervisorArchiveList(): SupervisorArchiveListItemForSettlementRestModel[] {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveList;
    }

    get supervisorArchiveListTotalCount(): number {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListTotalCount;
    }

    get contractorSenderFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisorForSettlement.contractorSenderFound;
    }

    get contractorPayerFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisorForSettlement.contractorPayerFound;
    }

    get selectedContractorSenderListForFilter(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisorForSettlement.selectedContractorSenderListForFilter;
    }

    set selectedContractorSenderListForFilter(val: ContractorSearchItemModel[]) {
        this.$store.direct.commit.supervisorForSettlement.setSelectedContractorSenderListForFilter(val);
    }

    get selectedContractorPayerListForFilter(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisorForSettlement.selectedContractorPayerListForFilter;
    }

    set selectedContractorPayerListForFilter(val: ContractorSearchItemModel[]) {
        this.$store.direct.commit.supervisorForSettlement.setSelectedContractorPayerListForFilter(val);
    }

    get contractorCarrierFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisorForSettlement.contractorCarrierFound;
    }

    get selectedContractorCarrierListForFilter(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisorForSettlement.selectedContractorCarrierListForFilter;
    }

    set selectedContractorCarrierListForFilter(val: ContractorSearchItemModel[]) {
        this.$store.direct.commit.supervisorForSettlement.setSelectedContractorCarrierListForFilter(val);
    }

    get supervisorCmrAttachmentData(): string | undefined {
        return this.$store.direct.state.supervisorForSettlement.supervisorCmrAttachmentData;
    }

    set supervisorCmrAttachmentData(val: string | undefined) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorCmrAttachmentData(val);
    }

    get currentUserRoles(): string[] {
        return this.$store.direct.state.auth.userRoles;
    }

    get contractorAssets(): ContractorAssetsRestModel {
        return this.$store.direct.state.contractor.contractorAssets;
    }

    get userIsAdminOrAccountant(): boolean {
        if (this.currentUserRoles.includes(Roles.Admin) || this.currentUserRoles.includes(Roles.Accountant) || this.currentUserRoles.includes(Roles.AccountantAdmin)) {
            return true;
        }
        return false;
    }

    get userIsAccountant(): boolean {
        if (this.currentUserRoles.includes(Roles.Accountant) || this.currentUserRoles.includes(Roles.AccountantAdmin)) {
            return true;
        }
        return false;
    }

    get supervisorList(): SupervisorForSettlementRestModel[] {
        return this.$store.direct.state.settlementCommon.supervisorList;
    }

    set supervisorList(val: SupervisorForSettlementRestModel[]) {
        this.$store.direct.commit.settlementCommon.setSupervisorList(val);
    }

    get incomeDocument() {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocument;
    }

    set incomeDocument(val: SettlementIncomeDocumentRestModel) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocument(val);
    }

    get contractorDetailsForSettlement(): ContractorDetailsForSettlementRestModel {
        return this.$store.direct.state.contractor.contractorDetailsForSettlement;
    }

    get incomeDocumentSaleDate() {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentSaleDate;
    }

    set incomeDocumentSaleDate(val: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentSaleDate(val);
    }

    get supervisor(): SupervisorRestModel {
        return this.$store.direct.state.supervisor.supervisor;
    }

    get branchList(): ListItemNumberModel[] {
        const branches = this.$store.direct.state.contractor.contractorAssets.branches;
        let clonedBranchList = [] as ListItemNumberModel[];
        if (branches == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        else {
            clonedBranchList = [...branches];
            clonedBranchList.push({ value: 0, text: i18n.t("common.all") } as ListItemNumberModel);
        }
        return clonedBranchList;
    }

    get departmentList(): DepartmentItemRestModel[] {
        const departmentList = this.$store.direct.state.contractor.contractorAssets.departments;
        if (departmentList == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        return departmentList;
    }

    get backToSupervisorArchiveListForSettlement(): boolean {
        return this.$store.direct.state.supervisorForSettlement.backToSupervisorArchiveListForSettlement;
    }

    set backToSupervisorArchiveListForSettlement(val: boolean) {
        this.$store.direct.commit.supervisorForSettlement.setBackToSupervisorArchiveListForSettlement(val);
    }

    get filterAsActive(): boolean {
        return this.$store.direct.state.supervisorForSettlement.supervisorArchiveListFilterAsActive;
    }

    set filterAsActive(val: boolean) {
        this.$store.direct.commit.supervisorForSettlement.setSupervisorArchiveListFilterAsActive(val);
    }

    @Watch("contractorSenderSearch")
    async contractorSenderSearchLoadItems(val: string) {
        await this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [ContractorTypes.Client], contractorPropertiesEnabled: [], source: "supervisorForSettlement" });
        if (this.selectedContractorSenderListForFilter.length > 0) {
            this.selectedContractorSenderListForFilter.forEach((x: any) => {
                if (!this.contractorSenderFound.includes(x)) {
                    this.contractorSenderFound.push(x);
                }
            })
        }
    }

    @Watch("contractorPayerSearch")
    async contractorPayerSearchLoadItems(val: string) {
        await this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [ContractorTypes.Payer], contractorPropertiesEnabled: [], source: "supervisorForSettlement" });
        if (this.selectedContractorPayerListForFilter.length > 0) {
            this.selectedContractorPayerListForFilter.forEach((x: any) => {
                if (!this.contractorPayerFound.includes(x)) {
                    this.contractorPayerFound.push(x);
                }
            })
        }
    }

    @Watch("contractorCarrierSearch")
    async contractorCarrierSearchLoadItems(val: string) {
        await this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [ContractorTypes.Carrier], contractorPropertiesEnabled: [], source: "supervisorForSettlement" });
        if (this.selectedContractorCarrierListForFilter.length > 0) {
            this.selectedContractorCarrierListForFilter.forEach((x: any) => {
                if (!this.contractorCarrierFound.includes(x)) {
                    this.contractorCarrierFound.push(x);
                }
            })
        }
    }

    @Watch("pagerOptions", { deep: true })
    pagerOptionsHandles() {
        this.loadSupervisorArchiveListForSettlement();
    }

    @Watch("twoWayPlaceSearching")
    twoWayPlaceSearchingHandler(val: any) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchPlaceType")
    searchPlaceTypeHandler(val: any) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchPlaceName")
    searchPlaceNameHandler(val: string) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchPlaceCountry")
    searchPlaceCountryHandler(val: string) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchPlacePostalCode")
    searchPlacePostalCodeHandler(val: string) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchPlaceCity")
    searchPlaceCityHandler(val: string) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchPlaceStreet")
    searchPlaceStreetHandler(val: string) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchPlanningDateStartDate")
    searchPlanningDateStartDateHandler(val: string) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchPlanningDateEndDate")
    searchPlanningDateEndDateHandler(val: string) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchRealDateStartDate")
    searchRealDateStartDateHandler(val: string) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchRealDateEndDate")
    searchRealDateEndDateHandler(val: string) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchUnloadingPlaceType")
    searchUnloadingPlaceTypeHandler(val: string) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchUnloadingPlaceName")
    searchUnloadingPlaceNameHandler(val: string) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchUnloadingPlaceCountry")
    searchUnloadingPlaceCountryHandler(val: string) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchUnloadingPlacePostalCode")
    searchUnloadingPlacePostalCodeHandler(val: string) {
        this.checkIfFilterIsActive();
    }
    @Watch("clearSearchUnloadingPlaceCityClick")
    searchUnloadingPlaceCityHandler(val: string) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchUnloadingPlaceStreet")
    searchUnloadingPlaceStreetHandler(val: string) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchUnloadingPlanningDateStartDate")
    searchUnloadingPlanningDateStartDateHandler(val: string) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchUnloadingPlanningDateEndDate")
    searchUnloadingPlanningDateEndDateHandler(val: string) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchUnloadingRealDateStartDate")
    searchUnloadingRealDateStartDateHandler(val: string) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchUnloadingRealDateEndDate")
    searchUnloadingRealDateEndDateHandler(val: string) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchOrderId")
    searchOrderIdHandler(val: string) {
        this.checkIfFilterIsActive();
    }
    @Watch("localStorageApplicationStateData.supervisorListSelectedDepartments")
    localStorageApplicationStateDataSupervisorListSelectedDepartmentsHandler(val: any) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchRegistrationNumber")
    searchRegistrationNumberHandler(val: string) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchVehicleTypeList")
    searchVehicleTypeListHandler(val: any) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchDriver1Name")
    searchDriver1NameHandler(val: string) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchDriver2Name")
    searchDriver2NameHandler(val: string) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchComment")
    searchCommentHandler(val: string) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchIsADR")
    searchIsADRHandler(val: any) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchTaillift")
    searchTailliftHandler(val: any) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchExtinguisher")
    searchExtinguisherHandler(val: any) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchHasCmrFile")
    searchHasCmrFileHandler(val: any) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchHasCostDocument")
    searchHasCostDocumentHandler(val: any) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchHasIncomeDocument")
    searchHasIncomeDocumentHandler(val: any) {
        this.checkIfFilterIsActive();
    }
    @Watch("searchIsStorno")
    searchIsStornoHandler(val: any) {
        this.checkIfFilterIsActive();
    }

    created() {
        if (this.selectedContractorSenderListForFilter == null || this.selectedContractorSenderListForFilter == undefined) {
            this.selectedContractorSenderListForFilter = [];
        }
        if (this.selectedContractorCarrierListForFilter == null || this.selectedContractorCarrierListForFilter == undefined) {
            this.selectedContractorCarrierListForFilter = [] as ContractorSearchItemModel[];
        }
    }

    checkIfFilterIsActive() {
        if (this.twoWayPlaceSearching == false &&
            this.searchPlaceType == 0 &&
            this.searchUnloadingPlaceType == 0 &&
            this.searchPlaceName === "" &&
            this.searchUnloadingPlaceName === "" &&
            this.searchPlacePostalCode === "" &&
            this.searchPlaceCity === "" &&
            this.searchPlaceStreet === "" &&
            this.searchPlanningDateStartDate === "" &&
            this.searchPlanningDateEndDate === "" &&
            this.searchRealDateStartDate === "" &&
            this.searchRealDateEndDate === "" &&
            this.searchUnloadingPlaceCountry === "" &&
            this.searchUnloadingPlacePostalCode === "" &&
            this.searchUnloadingPlaceCity === "" &&
            this.searchUnloadingPlaceStreet === "" &&
            this.searchUnloadingPlanningDateStartDate === "" &&
            this.searchUnloadingPlanningDateEndDate === "" &&
            this.searchUnloadingRealDateStartDate === "" &&
            this.searchUnloadingRealDateEndDate === "" &&
            this.searchOrderId === "" &&
            this.localStorageApplicationStateData.supervisorListSelectedDepartments.length == 0 &&
            this.searchRegistrationNumber === "" &&
            this.searchVehicleTypeList.length == 0 &&
            this.searchDriver1Name === "" &&
            this.searchDriver2Name === "" &&
            this.searchPlaceCountry === "" &&
            this.searchIsADR == false &&
            this.searchTaillift == false &&
            this.searchExtinguisher == false &&
            this.searchHasCmrFile == false &&
            this.searchHasCostDocument == 0 &&
            this.searchHasIncomeDocument == 0 &&
            this.searchIsStorno == 0 &&
            this.searchComment === "") {
            this.filterAsActive = false;
        }
        else {
            this.filterAsActive = true;
        }
    }

    getSupervisorProfitItemStyle(supervisorProfit: number) {
        if (supervisorProfit > 0) {
            return "teal--text text--accent-4";
        }
        else if (supervisorProfit < 0) {
            return "red--text";
        }
        else {
            return "";
        }
    }

    getSupervisorItemStyle(item: SupervisorArchiveListItemForSettlementRestModel) {
        if (item.isStorno) {
            return "red--text text-decoration-line-through";
        }
        return "";
    }

    twoWayPlaceSearchingCheckboxChanged() {
        if (this.twoWayPlaceSearching == true) {
            this.searchPlaceType = 1;
            this.searchUnloadingPlaceType = 2;
        }
        else {
            this.searchPlaceType = 0;
        }
    }

    formatDateTime(time: any) {
        if (time != null) {
            return dayjs(time).format("YYYY-MM-DD HH:mm");
        }
    }

    formatDepartmentIdToDepartmentName(departmentId: any) {
        if (this.departmentList != undefined) {
            return this.departmentList.find(x => x.value == departmentId)?.text;
        }
    }

    clearLoadInPlaceClick() {
        this.searchLoadInPlace = "";
        this.loadSupervisorArchiveListForSettlement();
    }

    clearLoadOutPlaceClick() {
        this.searchLoadOutPlace = "";
        this.loadSupervisorArchiveListForSettlement();
    }

    searchClick() {
        this.loadSupervisorArchiveListForSettlement();
    }

    clearSearchPlaceNameClick() {
        this.searchPlaceName = "";
        this.loadSupervisorArchiveListForSettlement();
    }

    clearSearchUnloadingPlaceNameClick() {
        this.searchUnloadingPlaceName = "";
        this.loadSupervisorArchiveListForSettlement();
    }

    clearSearchPlaceCountryClick() {
        this.searchPlaceCountry = "";
        this.loadSupervisorArchiveListForSettlement();
    }

    clearSearchUnloadingPlaceCountryClick() {
        this.searchUnloadingPlaceCountry = "";
        this.loadSupervisorArchiveListForSettlement();
    }

    clearSearchPlacePostalCodeClick() {
        this.searchPlacePostalCode = "";
        this.loadSupervisorArchiveListForSettlement();
    }

    clearSearchUnloadingPlacePostalCodeClick() {
        this.searchUnloadingPlacePostalCode = "";
        this.loadSupervisorArchiveListForSettlement();
    }

    clearSearchPlaceCityClick() {
        this.searchPlaceCity = "";
        this.loadSupervisorArchiveListForSettlement();
    }

    clearSearchUnloadingPlaceCityClick() {
        this.searchUnloadingPlaceCity = "";
        this.loadSupervisorArchiveListForSettlement();
    }

    clearSearchPlaceStreetClick() {
        this.searchPlaceStreet = "";
        this.loadSupervisorArchiveListForSettlement();
    }

    clearSearchUnloadingPlaceStreetClick() {
        this.searchUnloadingPlaceStreet = "";
        this.loadSupervisorArchiveListForSettlement();
    }

    clearSearchContractorSenderClick() {
        this.selectedContractorSenderListForFilter = [] as ContractorSearchItemModel[];
        this.loadSupervisorArchiveListForSettlement();
    }

    clearSearchContractoPayerClick() {
        this.selectedContractorPayerListForFilter = [] as ContractorSearchItemModel[];
        this.loadSupervisorArchiveListForSettlement();
    }

    clearSearchContractorCarrierClick() {
        this.selectedContractorCarrierListForFilter = [] as ContractorSearchItemModel[];
        this.loadSupervisorArchiveListForSettlement();
    }

    clearSearchRegistrationNumberClick() {
        this.searchRegistrationNumber = "";
        this.loadSupervisorArchiveListForSettlement();
    }

    clearSearchOrderIdClick() {
        this.searchOrderId = "";
        this.loadSupervisorArchiveListForSettlement();
    }

    clearSearchCustomerOrderIdClick() {
        this.searchCustomerOrderId = "";
        this.loadSupervisorArchiveListForSettlement();
    }

    clearSearchDriver1NameClick() {
        this.searchDriver1Name = "";
        this.loadSupervisorArchiveListForSettlement();
    }

    clearSearchDriver2NameClick() {
        this.searchDriver2Name = "";
        this.loadSupervisorArchiveListForSettlement();
    }

    clearSearchCommentClick() {
        this.searchComment = "";
        this.loadSupervisorArchiveListForSettlement();
    }

    clearDateTimePicker(clearableValue: string) {
        if (clearableValue == "searchCreatedAtStartDateValue") {
            this.searchCreatedAtStartDate = "";
        }
        if (clearableValue == "searchCreatedAtEndDateValue") {
            this.searchCreatedAtEndDate = "";
        }
        if (clearableValue == "searchRealLoadInDateStartDateValue") {
            this.searchRealLoadInDateStartDate = "";
        }
        if (clearableValue == "searchRealLoadInDateEndDateValue") {
            this.searchRealLoadInDateEndDate = "";
        }
        if (clearableValue == "searchRealLoadOutDateStartDateValue") {
            this.searchRealLoadOutDateStartDate = "";
        }
        if (clearableValue == "searchRealLoadOutDateEndDateValue") {
            this.searchRealLoadOutDateEndDate = "";
        }
        if (clearableValue == "searchPlanningDateStartDateValue") {
            this.searchPlanningDateStartDate = "";
        }
        if (clearableValue == "searchPlanningDateEndDateValue") {
            this.searchPlanningDateEndDate = "";
        }
        if (clearableValue == "searchRealDateStartDateValue") {
            this.searchRealDateStartDate = "";
        }
        if (clearableValue == "searchRealDateEndDateValue") {
            this.searchRealDateEndDate = "";
        }
        if (clearableValue == "searchUnloadingPlanningDateStartDateValue") {
            this.searchUnloadingPlanningDateStartDate = "";
        }
        if (clearableValue == "searchUnloadingPlanningDateEndDateValue") {
            this.searchUnloadingPlanningDateEndDate = "";
        }
        if (clearableValue == "searchUnloadingRealDateStartDateValue") {
            this.searchUnloadingRealDateStartDate = "";
        }
        if (clearableValue == "searchUnloadingRealDateEndDateValue") {
            this.searchUnloadingRealDateEndDate = "";
        }
        this.loadSupervisorArchiveListForSettlement();
    }

    clearAllComplexFilters() {
        this.searchPlaceType = 0
        this.searchPlaceName = "";
        this.searchUnloadingPlaceName = "";
        this.searchPlaceCountry = "";
        this.searchUnloadingPlaceCountry = "";
        this.searchPlacePostalCode = "";
        this.searchUnloadingPlacePostalCode = "";
        this.searchPlaceCity = "";
        this.searchUnloadingPlaceCity = "";
        this.searchPlaceStreet = "";
        this.searchUnloadingPlaceStreet = "";
        this.searchCustomerIdcs = [] as number[];
        this.searchShippingCompanyIdcs = [];
        this.searchRegistrationNumber = "";
        this.searchOrderId = "";
        this.searchCustomerOrderId = "";
        this.localStorageApplicationStateData.supervisorListSelectedDepartments = [] as number[];
        this.searchBranchId = 0;
        this.searchVehicleTypeList = [] as string[];
        this.searchDriver1Name = "";
        this.searchDriver2Name = "";
        this.searchComment = "";
        this.searchIsADR = false;
        this.searchTaillift = false;
        this.searchExtinguisher = false;
        this.searchHasCmrFile = false;
        this.searchHasCostDocument = 0;
        this.searchHasIncomeDocument = 0;
        this.selectedContractorCarrierListForFilter = [] as ContractorSearchItemModel[];
        this.selectedContractorSenderListForFilter = [] as ContractorSearchItemModel[];
        this.searchCreatedAtStartDate = "";
        this.searchCreatedAtEndDate = "";
        this.searchRealLoadInDateStartDate = "";
        this.searchRealLoadInDateEndDate = "";
        this.searchRealLoadOutDateStartDate = "";
        this.searchRealLoadOutDateEndDate = "";
        this.searchPlanningDateStartDate = "";
        this.searchPlanningDateEndDate = "";
        this.searchRealDateStartDate = "";
        this.searchRealDateEndDate = "";
        this.searchUnloadingPlanningDateStartDate = "";
        this.searchUnloadingPlanningDateEndDate = "";
        this.searchUnloadingRealDateStartDate = "";
        this.searchUnloadingRealDateEndDate = "";
        this.searchIsStorno = 0;

        this.loadSupervisorArchiveListForSettlement();
    }

    addSupervisorForCreateIncomeDocumentList(item: SupervisorArchiveListItemForSettlementRestModel) {
        this.supervisorListForCreateIncomeDocument = this.supervisorArchiveList.filter((x: SupervisorArchiveListItemForSettlementRestModel) => {
            if (x.isMarkedForCreateIncomeDocument) {
                return x.id;
            }
        })
        if (this.supervisorListForCreateIncomeDocument.length == 0) {
            this.supervisorListForCreateIncomeDocumentBranchId = 0;
            this.supervisorListForCreateIncomeDocumentCustomerCompanyId = 0;
        }
        else {
            this.supervisorListForCreateIncomeDocumentCustomerCompanyId = this.supervisorListForCreateIncomeDocument[0].customerCompanyId as number;
            this.supervisorListForCreateIncomeDocumentBranchId = this.supervisorListForCreateIncomeDocument[0].branchId;
        }
    }

    async createIncomeDocumentFromSupervisorList() {
        await this.$router.push({ path: "/settlement/income-document/create" });

        this.supervisorList = this.supervisorListForCreateIncomeDocument as any as SupervisorForSettlementRestModel[];

        await this.supervisorList.forEach(async (x: SupervisorForSettlementRestModel) => {
            await this.$store.direct.dispatch.supervisor.loadSupervisor({ id: x.id });
            x.accountingComment = this.supervisor.accountingComment;
        })
        await this.$store.direct.dispatch.supervisor.loadSupervisor({ id: this.supervisorList[0].id });

        this.incomeDocument.incomeDocumentInvoiceTypeId = 1;

        if (this.supervisor.customerCompanyId != null) {
            await this.$store.direct.dispatch.contractor.getContractorDetailsForSettlement({ contractorId: this.supervisor.customerCompanyId });

            this.incomeDocument.contractor = { text: this.contractorDetailsForSettlement.name, value: this.contractorDetailsForSettlement.id } as ContractorSearchItemModel;
            this.incomeDocument.contractorName = this.contractorDetailsForSettlement.name;
            this.incomeDocument.contractorSymbol = this.contractorDetailsForSettlement.symbol;
            this.incomeDocument.contractorCountry = this.contractorDetailsForSettlement.country;
            this.incomeDocument.contractorCity = this.contractorDetailsForSettlement.city;
            this.incomeDocument.contractorPostalCode = this.contractorDetailsForSettlement.postalCode;
            this.incomeDocument.contractorStreetAndNumber = this.contractorDetailsForSettlement.streetAndNumber;
            this.incomeDocument.contractorTin = this.contractorDetailsForSettlement.tin;
            this.incomeDocument.contractorTinPrefix = this.contractorDetailsForSettlement.tinPrefix;

            this.incomeDocument.paymentTerm = this.supervisor.paymentTermSale;
            this.incomeDocument.currencyCode = this.supervisor.saleValueCurrency;
        }

        const foundDepartment = this.contractorAssets.departments.find((x: DepartmentItemRestModel) => {
            return x.value == this.supervisor.locationId;
        });
        if (foundDepartment != undefined) {
            this.incomeDocument.branchId = foundDepartment.branchId;

            if (foundDepartment.branchId == BranchEnum.DE) {
                this.incomeDocument.documentLanguage = "DE";
            }
            else {
                this.incomeDocument.documentLanguage = "PL";
            }
        }

        const saleDateList: string[] = [];

        this.supervisorListForCreateIncomeDocument.forEach((x: SupervisorArchiveListItemForSettlementRestModel) => {
            if (x.isStorno) {
                if (x.loadOutRealDate != null && x.loadOutRealDate != undefined) {
                    saleDateList.push(x.loadOutRealDate);
                }
                else if (x.stornoDate != null && x.stornoDate != undefined) {
                    saleDateList.push(x.stornoDate);
                }
                else {
                    saleDateList.push(x.createdAt);
                }
            }
            else {
                if (x.loadOutRealDate != undefined) {
                    saleDateList.push(x.loadOutRealDate);
                }
            }
        });

        this.incomeDocumentSaleDate = saleDateList.sort((x: string, y: string) => {
            return +new Date(y) - +new Date(x);
        })[0];

        this.supervisorList.sort((a: SupervisorForSettlementRestModel, b: SupervisorForSettlementRestModel) => (a.orderId > b.orderId) ? 1 : -1)

        const incomeDocumentItems = [] as SettlementIncomeDocumentItemRestModel[];
        const incomeDocumentItem = {
            description: "",
            descriptionDetailed: "",
            price: this.supervisorList.reduce((sum, current) => sum + current.saleValue, 0),
            taxRate: 0,
            quantity: 1,
            unit: "SZT.",
        } as SettlementIncomeDocumentItemRestModel;
        incomeDocumentItems.push(incomeDocumentItem);
        this.incomeDocument.incomeDocumentItems = incomeDocumentItems;
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentItemList(incomeDocumentItems);
    }

    editSupervisorRowClick(supervisor: SupervisorArchiveListItemForSettlementRestModel) {
        if (window.getSelection()?.toString()) {
            return
        }
        this.backToSupervisorArchiveListForSettlement = true;
        this.$router.push({ path: "/supervisor/edit/" + supervisor.id });
    }

    editSupervisorClick(supervisor: SupervisorArchiveListItemForSettlementRestModel) {
        this.backToSupervisorArchiveListForSettlement = true;
        return "/supervisor/edit/" + supervisor.id;
    }

    supervisorListClick() {
        this.$router.push({ path: "/supervisor/list" });
    }

    showSupervisorAttachmentClick(supervisorId: number) {
        return "/supervisor/attachment-list/" + supervisorId;
    }

    downloadPDFSupervisorDocument(supervisor: SupervisorArchiveListItemForSettlementRestModel, fullVersion: boolean) {
        this.$store.direct.dispatch.supervisor.downloadPDFSupervisorDocument({ supervisorId: supervisor.id, fullVersion: fullVersion });
    }

    async isWithoutInvoicingChangeClick(item: SupervisorRestModel) {
        await this.$store.direct.dispatch.supervisorForSettlement.isWithoutInvoicingChanged({ supervisorId: item.id, isChecked: !item.isWithoutInvoicing });
        this.loadSupervisorArchiveListForSettlement()
    }

    async isServiceCompletedChangeClick(item: SupervisorRestModel) {
        await this.$store.direct.dispatch.supervisorForSettlement.isServiceCompletedChanged({ supervisorId: item.id, isChecked: !item.isServiceCompleted });
        this.loadSupervisorArchiveListForSettlement()
    }

    getCmrData(item: SupervisorRestModel) {
        this.$store.direct.dispatch.supervisorForSettlement.getSupervisorCmrAttachmentData({ supervisorId: item.id });
    }

    clearCmrData() {
        this.supervisorCmrAttachmentData = undefined as any;
    }

    async openSupervisorSettlementListDialog(item: SupervisorArchiveListItemForSettlementRestModel) {
        await this.$store.direct.dispatch.settlementCostDocument.loadSupervisorCostDocumentList({ supervisorId: item.id });
        await this.$store.direct.dispatch.settlementIncomeDocument.loadSupervisorIncomeDocumentList({ supervisorId: item.id });
        await this.$store.direct.dispatch.supervisor.loadSupervisor({ id: item.id });
        this.$store.direct.dispatch.supervisor.openSupervisorSettlementDialog()
            .then(async confirm => {
                if (confirm) {
                    this.loadSupervisorArchiveListForSettlement();
                }
            });
    }

    loadSupervisorArchiveListForSettlement() {
        if (this.selectedContractorSenderListForFilter != undefined && this.selectedContractorSenderListForFilter.length > 0) {
            this.searchCustomerIdcs = this.selectedContractorSenderListForFilter.map(x => x.value);
        }
        else {
            this.searchCustomerIdcs = [];
        }
        if (this.selectedContractorPayerListForFilter != undefined && this.selectedContractorPayerListForFilter.length > 0) {
            this.searchPayerIdcs = this.selectedContractorPayerListForFilter.map(x => x.value);
        }
        else {
            this.searchPayerIdcs = [];
        }
        if (this.selectedContractorCarrierListForFilter != undefined && this.selectedContractorCarrierListForFilter.length > 0) {
            this.searchShippingCompanyIdcs = this.selectedContractorCarrierListForFilter.map(x => x.value);
        }
        else {
            this.searchShippingCompanyIdcs = [];
        }

        this.$store.direct.dispatch.supervisorForSettlement.loadSupervisorArchiveListForSettlement({
            requestModel: this.prepareSupervisorArchiveListForSettlementRequestModel()
        });
    }

    createXlsReportClick() {
        this.$store.direct.dispatch.supervisorForSettlement.downloadXlsReportSupervisorArchiveListForSettlement({
            requestModel: this.prepareSupervisorArchiveListForSettlementRequestModel()
        });
    }

    prepareSupervisorArchiveListForSettlementRequestModel(): SupervisorArchiveListForSettlementRequestModel {
        return {
            page: this.pagerOptions.page,
            pageSize: this.pagerOptions.itemsPerPage,
            sortBy: this.pagerOptions.sortBy[0],
            sortDesc: this.pagerOptions.sortDesc[0],
            searchLoadInPlace: this.searchLoadInPlace,
            searchLoadOutPlace: this.searchLoadOutPlace,
            searchPlaceType: this.searchPlaceType,
            searchUnloadingPlaceType: this.searchUnloadingPlaceType,
            searchPlaceName: this.searchPlaceName,
            searchUnloadingPlaceName: this.searchUnloadingPlaceName,
            searchPlaceCountry: this.searchPlaceCountry,
            searchUnloadingPlaceCountry: this.searchUnloadingPlaceCountry,
            searchPlacePostalCode: this.searchPlacePostalCode,
            searchUnloadingPlacePostalCode: this.searchUnloadingPlacePostalCode,
            searchPlaceCity: this.searchPlaceCity,
            searchUnloadingPlaceCity: this.searchUnloadingPlaceCity,
            searchPlaceStreet: this.searchPlaceStreet,
            searchUnloadingPlaceStreet: this.searchUnloadingPlaceStreet,
            searchCustomerIdcs: this.searchCustomerIdcs,
            searchPayerIdcs: this.searchPayerIdcs,
            searchShippingCompanyIdcs: this.searchShippingCompanyIdcs,
            searchRegistrationNumber: this.searchRegistrationNumber,
            searchOrderId: this.searchOrderId,
            searchCustomerOrderId: this.searchCustomerOrderId,
            searchCreatedAtStartDate: this.searchCreatedAtStartDate,
            searchCreatedAtEndDate: this.searchCreatedAtEndDate,
            searchRealLoadInDateStartDate: this.searchRealLoadInDateStartDate,
            searchRealLoadInDateEndDate: this.searchRealLoadInDateEndDate,
            searchRealLoadOutDateStartDate: this.searchRealLoadOutDateStartDate,
            searchRealLoadOutDateEndDate: this.searchRealLoadOutDateEndDate,
            searchPlanningDateStartDate: this.searchPlanningDateStartDate,
            searchPlanningDateEndDate: this.searchPlanningDateEndDate,
            searchRealDateStartDate: this.searchRealDateStartDate,
            searchRealDateEndDate: this.searchRealDateEndDate,
            searchUnloadingPlanningDateStartDate: this.searchUnloadingPlanningDateStartDate,
            searchUnloadingPlanningDateEndDate: this.searchUnloadingPlanningDateEndDate,
            searchUnloadingRealDateStartDate: this.searchUnloadingRealDateStartDate,
            searchUnloadingRealDateEndDate: this.searchUnloadingRealDateEndDate,
            searchBranchId: this.searchBranchId,
            searchDepartments: this.localStorageApplicationStateData.supervisorListSelectedDepartments,
            searchVehicleTypes: this.searchVehicleTypeList,
            searchDriver1Name: this.searchDriver1Name,
            searchDriver2Name: this.searchDriver2Name,
            searchComment: this.searchComment,
            searchIsADR: this.searchIsADR,
            searchTaillift: this.searchTaillift,
            searchExtinguisher: this.searchExtinguisher,
            searchHasCmrFile: this.searchHasCmrFile,
            searchHasCostDocument: this.searchHasCostDocument,
            searchHasIncomeDocument: this.searchHasIncomeDocument,
            searchIsStorno: this.searchIsStorno,
            twoWayPlaceSearching: this.twoWayPlaceSearching,
            showSupervisorsAlsoWithoutPurchaseAndSaleValue: this.showSupervisorsAlsoWithoutPurchaseAndSaleValue,
        } as SupervisorArchiveListForSettlementRequestModel
    }

    hideFiltersMenuClick() {
        this.showFiltersMenu = false;
    }
}
