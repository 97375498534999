import "./css/site.css";
import Vue from "vue";
import store from "@/store";
import App from "@/components/app/app.vue";
import defaultDataTable from "@/components/custom/default-data-table";
import dateTimePicker from "@/components/custom/date-time-picker.vue";
import multipleSelect from "@/components/custom/multiple-select.vue";
import router from "@/router";
import i18n from "@/assets/i18n";
import SortableTable from "@/components/custom/sortable-table-directive";


//plugins
import "./plugins/axios";
import vuetify from "./plugins/vuetify";
import "./plugins/apexcharts";

Vue.component("DefaultDataTable", defaultDataTable);
Vue.component("DateTimePicker", dateTimePicker);
Vue.component("MultipleSelect", multipleSelect);

Vue.directive("sortable-table", SortableTable);

Vue.filter('formatCurrency', function (value: any) {
    if (typeof value !== "number") {
        return value;
    }
    return value.toLocaleString('pl-PL');
});

const vue = new Vue({
  i18n,
  router,
  store: store.original,
  vuetify,
  render: h => h(App)
}).$mount("#app");

export default vue;
