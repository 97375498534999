import Vue from "vue";
import { Component } from "vue-property-decorator";
import FilterTemplate from "../../layouts/filters/filter-template.vue";
import BaseCriterionModel from "../../models/baseCriterionModel";
import { BaseCriterionNameEnum } from "../../models/emums/baseCriterionNameEnum";
import { Roles } from "../../models/emums/roles";
import ListItemNumberModel from "../../models/listItemNumberModel";
import ContractorSearchItemModel from "../../models/rest/contractor/contractorSearchItemModel";
import SupervisorReportRestModel from "../../models/rest/supervisor-report/supervisorReportRestModel";

@Component({
    components: {
        "filter-template": FilterTemplate,
    }
})

export default class SupervisorReport extends Vue {
    dateTypes = [
        { text: "Loading date", value: 1 },
        { text: "Unloading date", value: 2 },
        { text: "Creation date", value: 3 },
    ]

    supervisorReportListHeaders = [
        {
            text: this.$t("contractor.currency"),
            align: "start",
            value: "currency",
            sortable: false,
        },
        {
            text: this.$t("supervisor.numberOfSupervisorOrders"),
            align: "start",
            value: "numberOfSupervisorOrders",
            sortable: false,
        },
        {
            text: this.$t("supervisor.customerFreight"),
            align: "start",
            value: "saleValue",
            sortable: false
        },
        {
            text: this.$t("supervisor.forwarderFreight"),
            align: "start",
            value: "purchaseValue",
            sortable: false
        },
        {
            text: this.$t("supervisor.profitValue"),
            align: "start",
            value: "profitValue",
            sortable: false
        },
        {
            text: this.$t("supervisor.profitPercentageValue"),
            align: "start",
            value: "profitPercentageValue",
            sortable: false
        },
    ];

    pagerOptions = {
        page: 1,
        itemsPerPage: -1,
    };

    get supervisorReportList(): SupervisorReportRestModel[] {
        return this.$store.direct.state.supervisorReport.supervisorReportList;
    }

    get supervisorReportListLoading(): boolean {
        return this.$store.direct.state.supervisorReport.supervisorReportListLoading;
    }

    get supervisorReportListTotalCount(): number {
        return this.$store.direct.state.supervisorReport.supervisorReportListTotalCount;
    }

    get contractorSenderFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisor.contractorSenderFound;
    }

    get contractorPayerFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisor.contractorPayerFound;
    }

    get contractorCarrierFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisor.contractorCarrierFound;
    }

    get departments(): ListItemNumberModel[] {
        const departments = this.$store.direct.state.contractor.contractorAssets.departments;
        departments.push({ value: 0, text: "Not set", defaulSupervisorCarrierOrderRequirementId: 0, branchId: 0 });
        return departments;
    }

    get contractorAssets() {
        return this.$store.direct.state.contractor.contractorAssets;
    }

    get userListSorted(): any {
        return this.contractorAssets.crmUsers?.sort((prev, next) => (prev.text > next.text ? 1 : -1));
    }

    get criterionList(): BaseCriterionModel[] {
        const criterionList = this.$store.direct.state.supervisorReport.supervisorCriterionList;

        const criterionListMutated = [...criterionList];

        criterionListMutated.forEach((criterion: BaseCriterionModel) => {
            if (criterion.name == BaseCriterionNameEnum.dateType) {
                criterion.items = this.dateTypes;
                criterion.label = this.$t("supervisor.dateType").toString();
            }
            else if (criterion.name == BaseCriterionNameEnum.dateFrom) {
                criterion.label = this.$t("common.dateFrom").toString();
            }
            else if (criterion.name == BaseCriterionNameEnum.dateTo) {
                criterion.label = this.$t("common.dateTo").toString();
            }
            else if (criterion.name == BaseCriterionNameEnum.departmentList) {
                criterion.items = this.departments;
                criterion.label = this.$t("supervisor.branchDepartment").toString();
            }
            else if (criterion.name == BaseCriterionNameEnum.contractorSenders) {
                criterion.items = this.contractorSenderFound;
                criterion.label = this.$t("supervisor.sender").toString();
            }
            else if (criterion.name == BaseCriterionNameEnum.contractorPayers) {
                criterion.items = this.contractorPayerFound;
                criterion.label = this.$t("supervisor.payer").toString();
            }
            else if (criterion.name == BaseCriterionNameEnum.contractorCarriers) {
                criterion.items = this.contractorCarrierFound;
                criterion.label = this.$t("supervisor.shippingCompany").toString();
            }
            else if (criterion.name == BaseCriterionNameEnum.contractorSendersAccountManagers) {
                criterion.items = this.userListSorted;
                criterion.label = this.$t("contractor.accountManagers").toString();
            }
            else if (criterion.name == BaseCriterionNameEnum.includeContractorSenders) {
                criterion.label = criterion.value == true ? this.$t("supervisor.senderIncluded").toString() : this.$t("supervisor.senderExcluded").toString();
            }
            else if (criterion.name == BaseCriterionNameEnum.includeContractorPayers) {
                criterion.label = criterion.value == true ? this.$t("supervisor.payerIncluded").toString() : this.$t("supervisor.payerExcluded").toString();
            }
            else if (criterion.name == BaseCriterionNameEnum.includeContractorCarriers) {
                criterion.label = criterion.value == true ? this.$t("supervisor.shippingCompanyIncluded").toString() : this.$t("supervisor.shippingCompanyExcluded").toString();
            }
        });
        return criterionListMutated;
    }

    get supervisorReportGraphs(): any[] {
        return this.$store.direct.state.supervisorReport.supervisorReportGraphs;
    }

    get currentUserRoles(): string[] {
        return this.$store.direct.state.auth.userRoles;
    }

    get allowSearchContractorCarriers(): boolean {
        return this.$store.direct.state.supervisorReport.allowSearchContractorCarriers;
    }

    set allowSearchContractorCarriers(val: boolean) {
        this.$store.direct.commit.supervisorReport.setAllowSearchContractorCarriers(val);
    }

    saveIfAllowSearchContractorCarriers() {
        if (this.currentUserRoles.includes(Roles.Admin)) {
            this.allowSearchContractorCarriers = true;
        }
        else {
            this.allowSearchContractorCarriers = false;
        }
    }

    async created() {
        await this.$store.direct.dispatch.contractor.loadContractorAssets();
        this.loadReportList();
        this.loadReportGraph();
        this.saveIfAllowSearchContractorCarriers();
    }

    searchClick() {
        this.loadReportList(this.criterionList);
        this.loadReportGraph(this.criterionList);
    }

    async loadReportList(criterionList?: BaseCriterionModel[]) {
        if (criterionList != undefined) {
            await this.$store.direct.dispatch.supervisorReport.loadSupervisorReportList({ criterionList: criterionList });
        } else {
            await this.$store.direct.dispatch.supervisorReport.loadSupervisorReportList({ criterionList: [] });
        }
    }

    async loadReportGraph(criterionList?: BaseCriterionModel[]) {
        if (criterionList != undefined) {
            await this.$store.direct.dispatch.supervisorReport.supervisorReportGraphUpdate({ criterionList: criterionList });
        } else {
            await this.$store.direct.dispatch.supervisorReport.supervisorReportGraphUpdate({ criterionList: [] });
        }
    }

    async getSupervisorReportXlsExport() {
        await this.$store.direct.dispatch.supervisorReport.getSupervisorReportXlsExport({ criterionList: this.criterionList });
    }

    destroyed() {
        this.allowSearchContractorCarriers = false;
    }
}
