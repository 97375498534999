import { moduleActionContext } from "@/store/index";
import { AxiosError, AxiosResponse } from "axios";
import { defineModule } from "direct-vuex";
import { saveFile } from "../../assets/file-saver";
import i18n from "../../assets/i18n";
import { SupervisorAssignedTypeEnum } from "../../models/emums/supervisor/supervisorAssignedTypeEnum";
import { SupervisorPlaceTypeEnum } from "../../models/emums/supervisor/supervisorPlaceTypeEnum";
import ListItemNumberModel from "../../models/listItemNumberModel";
import ListItemStringModel from "../../models/listItemStringModel";
import ContractorContactPersonRestModel from "../../models/rest/contractor/contractorContactPersonRestModel";
import ContractorSearchItemModel from "../../models/rest/contractor/contractorSearchItemModel";
import SupervisorSaveAttachmentResponseModel from "../../models/rest/saveAttachmentResponseModel";
import SupervisorArchiveListRequestModel from "../../models/rest/supervisor/supervisorArchiveListRequestModel";
import SupervisorAttachmentRestModel from "../../models/rest/supervisor/supervisorAttachmentRestModel";
import SupervisorCarrierDocumentOrderRequirementRestModel from "../../models/rest/supervisor/supervisorCarrierDocumentOrderRequirementRestModel";
import SupervisorCheckpointRestModel from "../../models/rest/supervisor/supervisorCheckpointRestModel";
import SupervisorIntegrationRestModel from "../../models/rest/supervisor/supervisorIntegrationRestModel";
import SupervisorItemRestModel from "../../models/rest/supervisor/supervisorItemRestModel";
import SupervisorListRequestModel from "../../models/rest/supervisor/supervisorListRequestModel";
import SupervisorMementoItemRestModel from "../../models/rest/supervisor/supervisorMementoItemRestModel";
import SupervisorPlaceRestModel from "../../models/rest/supervisor/supervisorPlaceRestModel";
import SupervisorProcedureRestModel from "../../models/rest/supervisor/supervisorProcedureRestModel";
import SupervisorRestModel from "../../models/rest/supervisor/supervisorRestModel";
import SupervisorSendToEmailAddressModel from "../../models/rest/supervisor/supervisorSendToEmailAddressModel";
import { axiosInstance } from "../../plugins/axios";

export interface SupervisorModuleStateInterface {
    activeSupervisorsPlacesType: number,
    assignedPreliminarySupervisorsType: number,
    departmentList: ListItemNumberModel[],
    supervisor: SupervisorRestModel,
    supervisorListLoading: boolean;
    supervisorList: SupervisorItemRestModel[],
    supervisorListTotalCount: number,
    supervisorExcludedCustomersList: SupervisorItemRestModel[],
    supervisorExcludedCustomersListTotalCount: number,
    supervisorCheckpointList: SupervisorCheckpointRestModel[],
    supervisorCheckpointListTotalCount: number,
    validationError: any
    supervisorArchiveListLoading: boolean;
    supervisorArchiveList: SupervisorItemRestModel[],
    supervisorArchiveListTotalCount: number,
    supervisorPlace: SupervisorPlaceRestModel,
    supervisorListSearchText: string,
    supervisorListSearchPlacePostalCode: string,
    supervisorListSearchPhoneNumber: string,
    supervisorListPagerOptions: any,
    supervisorExcludedCustomersListPagerOptions: any,
    supervisorCheckpointsListPagerOptions: any,
    supervisorArchiveListSearchText: string,
    supervisorArchiveListSearchLoadInPlace: string,
    supervisorArchiveListSearchLoadOutPlace: string,
    supervisorArchiveListTwoWayPlaceSearching: boolean;
    supervisorArchiveListSearchPlaceType: number,
    supervisorArchiveListSearchUnloadingPlaceType: number,
    supervisorArchiveListSearchPlaceName: string,
    supervisorArchiveListSearchUnloadingPlaceName: string,
    supervisorArchiveListSearchPlaceCountry: string,
    supervisorArchiveListSearchUnloadingPlaceCountry: string,
    supervisorArchiveListSearchPlacePostalCode: string,
    supervisorArchiveListSearchUnloadingPlacePostalCode: string,
    supervisorArchiveListSearchPlaceCity: string,
    supervisorArchiveListSearchUnloadingPlaceCity: string,
    supervisorArchiveListSearchPlaceStreet: string,
    supervisorArchiveListSearchUnloadingPlaceStreet: string,
    supervisorArchiveListSearchCustomerIdcs: number[],
    supervisorArchiveListSearchShippingCompanyIdcs: number[],
    supervisorArchiveListSearchRegistrationNumber: string,
    supervisorArchiveListSearchOrderId: string,
    supervisorArchiveListSearchVehicleTypeList: string[],
    supervisorArchiveListSearchRDriver1Name: string,
    supervisorArchiveListSearchDriver2Name: string,
    supervisorArchiveListSearchComment: string,
    supervisorArchiveListSearchIsADR: boolean,
    supervisorArchiveListSearchTaillift: boolean,
    supervisorArchiveListSearchExtinguisher: boolean,
    supervisorArchiveListSearchNoCostDocument: boolean,
    supervisorArchiveListSearchNoIncomeDocument: boolean,
    supervisorArchiveListSearchPlanningDateStartDate: string,
    supervisorArchiveListSearchUnloadingPlanningDateStartDate: string,
    supervisorArchiveListSearchPlanningDateEndDate: string,
    supervisorArchiveListSearchUnloadingPlanningDateEndDate: string,
    supervisorArchiveListSearchRealDateStartDate: string,
    supervisorArchiveListSearchUnloadingRealDateStartDate: string,
    supervisorArchiveListSearchRealDateEndDate: string,
    supervisorArchiveListSearchUnloadingRealDateEndDate: string,
    supervisorArchiveListSearchIsStorno: number,
    supervisorArchiveListPagerOptions: any,
    isSupervisorPreliminary: boolean
    preliminarySupervisorList: SupervisorRestModel[],
    preliminarySupervisorListLoading: boolean
    preliminarySupervisorListTotalCount: number,
    preliminarySupervisorListPagerOptions: any,
    supervisorIntegrations: SupervisorIntegrationRestModel[],
    showIntegrationDialog: boolean,
    integrationDialogTitle: string,
    integrationDialogResolve: any;
    showSendingEmailDialog: boolean,
    sendingEmailDialogTitle: string,
    sendingEmailDialogResolve: any;
    sendingEmailDialogSendToEmailAddressArray: SupervisorSendToEmailAddressModel[],
    sendingEmailDialogSendFromEmailAddress: string,
    sendingEmailDialogEmailNote: string,
    sendingEmailDialogCarrierOrderRequirementId: number,
    supervisorCarrierDocumentOrderRequirementList: SupervisorCarrierDocumentOrderRequirementRestModel[],
    integrationStates: any[];
    occurredErrorWhileSaving: boolean;
    occurredErrorWhileSavingMessage: string;
    supervisorEditedByAnotherUser: boolean;
    supervisorPlaceOrderType: ListItemNumberModel[];
    showSupervisorPlaceForCheckDialog: boolean,
    supervisorPlaceForCheckList: SupervisorPlaceRestModel[];
    isSupervisorCustomChecked: any;
    supervisorPlaceNamesFound: SupervisorPlaceRestModel[];
    supervisorPlaceCitiesFound: SupervisorPlaceRestModel[];
    supervisorPlacePostalCodesFound: SupervisorPlaceRestModel[];
    selectedContractorSender: ContractorSearchItemModel;
    selectedContractorCarrier: ContractorSearchItemModel;
    selectedContractorPayer: ContractorSearchItemModel;
    selectedContractorSenderListForFilter: ContractorSearchItemModel[];
    selectedExcludedContractorSenderListForFilter: ContractorSearchItemModel[];
    selectedContractorPayerListForFilter: ContractorSearchItemModel[];
    selectedContractorCarrierListForFilter: ContractorSearchItemModel[];
    contractorSenderFound: ContractorSearchItemModel[];
    excludedContractorSenderFound: ContractorSearchItemModel[];
    contractorCarrierFound: ContractorSearchItemModel[];
    contractorPayerFound: ContractorSearchItemModel[];
    isDroppedFromPreliminaryList: boolean;
    vehicleTypes: ListItemStringModel[];
    showContractorDialog: boolean,
    contractorDialogTitle: string,
    contractorDialogContractorTypes: string[],
    contractorDialogResolve: any;
    contractorDialogContractorIsEdited: boolean;
    supervisorMementoListCount: number;
    supervisorMementoList: SupervisorMementoItemRestModel[];
    isSupervisorInHistoricalState: boolean;
    showAttachmentDialog: false | boolean;
    attachmentDialogResolve: any;
    attachmentList: SupervisorAttachmentRestModel[];
    attachmentDialogAttachments: any[],
    currentAttachment: any,
    procedureList: SupervisorProcedureRestModel[];
    showProcedureDialog: false | boolean;
    procedureDialogResolve: any;
    procedureDialogAttachments: any[],
    procedure: SupervisorProcedureRestModel,
    procedureFileList: ListItemNumberModel[];
    showSupervisorSettlementDialog: boolean,
    supervisorSettlementDialogResolve: any,
    supervisorSearch: SupervisorRestModel[];
    isSupervisorCopied: boolean;
    supervisorBaseToCopy: SupervisorRestModel;
    showSaveSupervisorDialog: boolean;
    titleSaveSupervisorDialog: string;
    messageSaveSupervisorDialog: string;
    resolveSaveSupervisorDialog: any;
    showSendingSmsDialog: boolean,
    sendingSmsDialogPhoneNumber: string,
    sendingSmsDialogResolve: any;
    contractorContactPerson: ContractorContactPersonRestModel;
    showContractorContactPersonDialog: false | boolean;
    contractorContactPersonDialogResolve: any;
}

const supervisorModule = defineModule({
    namespaced: true,
    state: {
        activeSupervisorsPlacesType: SupervisorPlaceTypeEnum.All,
        assignedPreliminarySupervisorsType: SupervisorAssignedTypeEnum.All,
        departmentList: [] as ListItemNumberModel[],
        supervisor: {} as SupervisorRestModel,
        supervisorListLoading: false,
        supervisorList: [] as SupervisorItemRestModel[],
        supervisorListTotalCount: 0,
        supervisorExcludedCustomersList: [] as SupervisorItemRestModel[],
        supervisorExcludedCustomersListTotalCount: 0,
        supervisorCheckpointList: [] as SupervisorCheckpointRestModel[],
        supervisorCheckpointListTotalCount: 0,
        validationError: {} as any,
        supervisorArchiveListLoading: false,
        supervisorArchiveList: [] as SupervisorItemRestModel[],
        supervisorArchiveListTotalCount: 0,
        supervisorPlace: {} as SupervisorPlaceRestModel,
        supervisorListSearchText: "",
        supervisorListSearchPlacePostalCode: "",
        supervisorListSearchPhoneNumber: "",
        supervisorListPagerOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["identificationData"],
            sortDesc: [true]
        },
        supervisorExcludedCustomersListPagerOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["identificationData"],
            sortDesc: [true]
        },
        supervisorCheckpointsListPagerOptions: {
            page: 1,
            itemsPerPage: 10
        },
        supervisorArchiveListSearchText: "",
        supervisorArchiveListSearchLoadInPlace: "",
        supervisorArchiveListSearchLoadOutPlace: "",
        supervisorArchiveListTwoWayPlaceSearching: false,
        supervisorArchiveListSearchPlaceType: 0,
        supervisorArchiveListSearchUnloadingPlaceType: 0,
        supervisorArchiveListSearchPlaceName: "",
        supervisorArchiveListSearchUnloadingPlaceName: "",
        supervisorArchiveListSearchPlaceCountry: "",
        supervisorArchiveListSearchUnloadingPlaceCountry: "",
        supervisorArchiveListSearchPlacePostalCode: "",
        supervisorArchiveListSearchUnloadingPlacePostalCode: "",
        supervisorArchiveListSearchPlaceCity: "",
        supervisorArchiveListSearchUnloadingPlaceCity: "",
        supervisorArchiveListSearchPlaceStreet: "",
        supervisorArchiveListSearchUnloadingPlaceStreet: "",
        supervisorArchiveListSearchCustomerIdcs: [] as number[],
        supervisorArchiveListSearchShippingCompanyIdcs: [] as number[],
        supervisorArchiveListSearchRegistrationNumber: "",
        supervisorArchiveListSearchOrderId: "",
        supervisorArchiveListSearchVehicleTypeList: [] as string[],
        supervisorArchiveListSearchRDriver1Name: "",
        supervisorArchiveListSearchDriver2Name: "",
        supervisorArchiveListSearchComment: "",
        supervisorArchiveListSearchIsADR: false,
        supervisorArchiveListSearchTaillift: false,
        supervisorArchiveListSearchExtinguisher: false,
        supervisorArchiveListSearchNoCostDocument: false,
        supervisorArchiveListSearchNoIncomeDocument: false,
        supervisorArchiveListSearchPlanningDateStartDate: "",
        supervisorArchiveListSearchUnloadingPlanningDateStartDate: "",
        supervisorArchiveListSearchPlanningDateEndDate: "",
        supervisorArchiveListSearchUnloadingPlanningDateEndDate: "",
        supervisorArchiveListSearchRealDateStartDate: "",
        supervisorArchiveListSearchUnloadingRealDateStartDate: "",
        supervisorArchiveListSearchRealDateEndDate: "",
        supervisorArchiveListSearchUnloadingRealDateEndDate: "",
        supervisorArchiveListSearchIsStorno: 0,
        supervisorArchiveListPagerOptions: {
            page: 1,
            itemsPerPage: 10
        },
        isSupervisorPreliminary: false,
        preliminarySupervisorList: [] as SupervisorRestModel[],
        preliminarySupervisorListLoading: false,
        preliminarySupervisorListTotalCount: 0,
        preliminarySupervisorListPagerOptions: {
            page: 1,
            itemsPerPage: 10
        },
        supervisorIntegrations: [] as SupervisorIntegrationRestModel[],
        showIntegrationDialog: false,
        integrationDialogTitle: "",
        integrationDialogResolve: null,
        integrationStates: [
            { id: 0, state: "Not set" },
            { id: 1, state: "Transport commissioned" },
            { id: 2, state: "Shipment loaded" },
            { id: 3, state: "Shipment delay customs" },
            { id: 4, state: "Shipment delay other" },
            { id: 5, state: "In transport" },
            { id: 6, state: "Shipment  delivered" }
        ],
        showSendingEmailDialog: false,
        sendingEmailDialogTitle: "",
        sendingEmailDialogResolve: null,
        sendingEmailDialogSendToEmailAddressArray: [] as SupervisorSendToEmailAddressModel[],
        sendingEmailDialogSendFromEmailAddress: "",
        sendingEmailDialogEmailNote: "",
        sendingEmailDialogCarrierOrderRequirementId: 0,
        supervisorCarrierDocumentOrderRequirementList: [] as SupervisorCarrierDocumentOrderRequirementRestModel[],
        supervisorPlaceOrderType: [
            { value: 1, text: "Loading" },
            { value: 2, text: "Unloading" },
            { value: 5, text: "Custom Clearance" },
        ],
        showSupervisorPlaceForCheckDialog: false,
        supervisorPlaceForCheckList: [],
        isSupervisorCustomChecked: {
            supervisorId: 0,
            isCustomChecked: false
        },
        occurredErrorWhileSaving: false,
        occurredErrorWhileSavingMessage: "",
        supervisorEditedByAnotherUser: false,
        supervisorPlaceNamesFound: [] as SupervisorPlaceRestModel[],
        supervisorPlaceCitiesFound: [] as SupervisorPlaceRestModel[],
        supervisorPlacePostalCodesFound: [] as SupervisorPlaceRestModel[],
        selectedContractorSender: {} as ContractorSearchItemModel,
        selectedContractorCarrier: {} as ContractorSearchItemModel,
        selectedContractorPayer: {} as ContractorSearchItemModel,
        selectedContractorSenderListForFilter: [] as ContractorSearchItemModel[],
        selectedExcludedContractorSenderListForFilter: [] as ContractorSearchItemModel[],
        selectedContractorPayerListForFilter: [] as ContractorSearchItemModel[],
        selectedContractorCarrierListForFilter: [] as ContractorSearchItemModel[],
        contractorSenderFound: [] as ContractorSearchItemModel[],
        excludedContractorSenderFound: [] as ContractorSearchItemModel[],
        contractorCarrierFound: [] as ContractorSearchItemModel[],
        contractorPayerFound: [] as ContractorSearchItemModel[],
        isDroppedFromPreliminaryList: false,
        vehicleTypes: [
            { text: "caddy", value: "caddy" },
            { text: "sprinter", value: "sprinter" },
            { text: "luton van", value: "luton van" },
            { text: "frigo", value: "frigo" },
            { text: "7,5t", value: "7,5t" },
            { text: "12t", value: "12t" },
            { text: "40t", value: "40t" },
        ] as ListItemStringModel[],
        showContractorDialog: false,
        contractorDialogTitle: "",
        contractorDialogContractorTypes: [] as string[],
        contractorDialogResolve: null,
        contractorDialogContractorIsEdited: false,
        supervisorMementoListCount: 0,
        supervisorMementoList: [] as SupervisorMementoItemRestModel[],
        isSupervisorInHistoricalState: false,
        showAttachmentDialog: false,
        attachmentDialogResolve: null,
        attachmentDialogAttachments: [] as any[],
        attachmentList: [] as SupervisorAttachmentRestModel[],
        currentAttachment: {} as any,
        procedureList: [] as SupervisorProcedureRestModel[],
        showProcedureDialog: false,
        procedureDialogResolve: null,
        procedureDialogAttachments: [] as any[],
        procedure: {} as SupervisorProcedureRestModel,
        procedureFileList: [] as ListItemNumberModel[],
        showSupervisorSettlementDialog: false,
        supervisorSettlementDialogResolve: null,
        supervisorSearch: [] as SupervisorRestModel[],
        isSupervisorCopied: false,
        supervisorBaseToCopy: {} as SupervisorRestModel,
        showSaveSupervisorDialog: false,
        titleSaveSupervisorDialog: "Default title",
        messageSaveSupervisorDialog: "Default message",
        resolveSaveSupervisorDialog: null,
        showSendingSmsDialog: false,
        sendingSmsDialogPhoneNumber: "",
        sendingSmsDialogResolve: null,
        contractorContactPerson: {} as ContractorContactPersonRestModel,
        emailAddress: {} as ListItemNumberModel,
        showContractorContactPersonDialog: false,
        contractorContactPersonDialogResolve: null,
    } as SupervisorModuleStateInterface,
    mutations: {
        setAssignedPreliminarySupervisorsType(state, value: number) {
            state.assignedPreliminarySupervisorsType = value;
        },
        setActiveSupervisorsPlacesType(state, value: number) {
            state.activeSupervisorsPlacesType = value;
        },
        setDepartmentList(state, value: ListItemNumberModel[]) {
            state.departmentList = value;
        },
        setSupervisor(state, value: SupervisorRestModel) {
            state.supervisor = value;
        },
        setSupervisorEditedAtValue(state, value: string) {
            state.supervisor.editedAt = value;
        },
        setSupervisorListLoading(state, value: boolean) {
            state.supervisorListLoading = value;
        },
        setSupervisorList(state, value: SupervisorItemRestModel[]) {
            state.supervisorList = value;
        },
        setSupervisorListTotalCount(state, value: number) {
            state.supervisorListTotalCount = value;
            if ((state.supervisorListPagerOptions.page * state.supervisorListPagerOptions.itemsPerPage) >= (value + state.supervisorListPagerOptions.itemsPerPage)) {
                state.supervisorListPagerOptions.page = 1;
            }
        },
        setSupervisorExcludedCustomersList(state, value: SupervisorItemRestModel[]) {
            state.supervisorExcludedCustomersList = value;
        },
        setSupervisorExcludedCustomersListTotalCount(state, value: number) {
            state.supervisorExcludedCustomersListTotalCount = value;
            if ((state.supervisorExcludedCustomersListPagerOptions.page * state.supervisorExcludedCustomersListPagerOptions.itemsPerPage) >= (value + state.supervisorExcludedCustomersListPagerOptions.itemsPerPage)) {
                state.supervisorExcludedCustomersListPagerOptions.page = 1;
            }
        },
        setSupervisorCheckpointList(state, value: SupervisorCheckpointRestModel[]) {
            state.supervisorCheckpointList = value;
        },
        setSupervisorCheckpointListTotalCount(state, value: number) {
            state.supervisorCheckpointListTotalCount = value;
            if ((state.supervisorCheckpointsListPagerOptions.page * state.supervisorCheckpointsListPagerOptions.itemsPerPage) >= (value + state.supervisorCheckpointsListPagerOptions.itemsPerPage)) {
                state.supervisorCheckpointsListPagerOptions.page = 1;
            }
        },
        setValidationError(state, value: any) {
            state.validationError = value;
        },
        setSupervisorArchiveListLoading(state, value: boolean) {
            state.supervisorArchiveListLoading = value;
        },
        setSupervisorArchiveList(state, value: SupervisorItemRestModel[]) {
            state.supervisorArchiveList = value;
        },
        setSupervisorArchiveListTotalCount(state, value: number) {
            state.supervisorArchiveListTotalCount = value;
            if ((state.supervisorArchiveListPagerOptions.page * state.supervisorArchiveListPagerOptions.itemsPerPage) >= (value + state.supervisorArchiveListPagerOptions.itemsPerPage)) {
                state.supervisorArchiveListPagerOptions.page = 1;
            }
        },
        setSupervisorPlace(state, value: SupervisorPlaceRestModel) {
            state.supervisorPlace = value;
        },
        setSupervisorListSearchText(state, value: string) {
            state.supervisorListSearchText = value;
        },
        setSupervisorListSearchPlacePostalCode(state, value: string) {
            state.supervisorListSearchPlacePostalCode = value;
        },
        setSupervisorListSearchPhoneNumber(state, value: string) {
            state.supervisorListSearchPhoneNumber = value;
        },
        setSupervisorListPagerOptions(state, value) {
            state.supervisorListPagerOptions = value;
        },
        setSupervisorExcludedCustomersListPagerOptions(state, value) {
            state.supervisorExcludedCustomersListPagerOptions = value;
        },
        setSupervisorCheckpointsListPagerOptions(state, value) {
            state.supervisorCheckpointsListPagerOptions = value;
        },
        setSupervisorArchiveListSearchText(state, value: string) {
            state.supervisorArchiveListSearchText = value;
        },
        setSupervisorArchiveListSearchLoadInPlace(state, value: string) {
            state.supervisorArchiveListSearchLoadInPlace = value;
        },
        setSupervisorArchiveListSearchLoadOutPlace(state, value: string) {
            state.supervisorArchiveListSearchLoadOutPlace = value;
        },
        setSupervisorArchiveListTwoWayPlaceSearching(state, value: boolean) {
            state.supervisorArchiveListTwoWayPlaceSearching = value;
        },
        setSupervisorArchiveListSearchPlaceType(state, value: number) {
            state.supervisorArchiveListSearchPlaceType = value;
        },
        setSupervisorArchiveListSearchUnloadingPlaceType(state, value: number) {
            state.supervisorArchiveListSearchUnloadingPlaceType = value;
        },
        setSupervisorArchiveListSearchPlaceName(state, value: string) {
            state.supervisorArchiveListSearchPlaceName = value;
        },
        setSupervisorArchiveListSearchUnloadingPlaceName(state, value: string) {
            state.supervisorArchiveListSearchUnloadingPlaceName = value;
        },
        setSupervisorArchiveListSearchPlaceCountry(state, value: string) {
            state.supervisorArchiveListSearchPlaceCountry = value;
        },
        setSupervisorArchiveListSearchUnloadingPlaceCountry(state, value: string) {
            state.supervisorArchiveListSearchUnloadingPlaceCountry = value;
        },
        setSupervisorArchiveListSearchPlacePostalCode(state, value: string) {
            state.supervisorArchiveListSearchPlacePostalCode = value;
        },
        setSupervisorArchiveListSearchUnloadingPlacePostalCode(state, value: string) {
            state.supervisorArchiveListSearchUnloadingPlacePostalCode = value;
        },
        setSupervisorArchiveListSearchPlaceCity(state, value: string) {
            state.supervisorArchiveListSearchPlaceCity = value;
        },
        setSupervisorArchiveListSearchUnloadingPlaceCity(state, value: string) {
            state.supervisorArchiveListSearchUnloadingPlaceCity = value;
        },
        setSupervisorArchiveListSearchPlaceStreet(state, value: string) {
            state.supervisorArchiveListSearchPlaceStreet = value;
        },
        setSupervisorArchiveListSearchUnloadingPlaceStreet(state, value: string) {
            state.supervisorArchiveListSearchUnloadingPlaceStreet = value;
        },
        setSupervisorArchiveListSearchCustomerIdcs(state, value: number[]) {
            state.supervisorArchiveListSearchCustomerIdcs = value;
        },
        setSupervisorArchiveListSearchShippingCompanyIdcs(state, value: number[]) {
            state.supervisorArchiveListSearchShippingCompanyIdcs = value;
        },
        setSupervisorArchiveListSearchRegistrationNumber(state, value: string) {
            state.supervisorArchiveListSearchRegistrationNumber = value;
        },
        setSupervisorArchiveListSearchOrderId(state, value: string) {
            state.supervisorArchiveListSearchOrderId = value;
        },
        setSupervisorArchiveListSearchVehicleTypeList(state, value: string[]) {
            state.supervisorArchiveListSearchVehicleTypeList = value;
        },
        setSupervisorArchiveListSearchDriver1Name(state, value: string) {
            state.supervisorArchiveListSearchRDriver1Name = value;
        },
        setSupervisorArchiveListSearchDriver2Name(state, value: string) {
            state.supervisorArchiveListSearchDriver2Name = value;
        },
        setSupervisorArchiveListSearchComment(state, value: string) {
            state.supervisorArchiveListSearchComment = value;
        },
        setSupervisorArchiveListSearchIsADR(state, value: boolean) {
            state.supervisorArchiveListSearchIsADR = value;
        },
        setSupervisorArchiveListSearchTaillift(state, value: boolean) {
            state.supervisorArchiveListSearchTaillift = value;
        },
        setSupervisorArchiveListSearchExtinguisher(state, value: boolean) {
            state.supervisorArchiveListSearchExtinguisher = value;
        },
        setSupervisorArchiveListSearchNoCostDocument(state, value: boolean) {
            state.supervisorArchiveListSearchNoCostDocument = value;
        },
        setSupervisorArchiveListSearchNoIncomeDocument(state, value: boolean) {
            state.supervisorArchiveListSearchNoIncomeDocument = value;
        },
        setSupervisorArchiveListSearchPlanningDateStartDate(state, value: string) {
            state.supervisorArchiveListSearchPlanningDateStartDate = value;
        },
        setSupervisorArchiveListSearchUnloadingPlanningDateStartDate(state, value: string) {
            state.supervisorArchiveListSearchUnloadingPlanningDateStartDate = value;
        },
        setSupervisorArchiveListSearchPlanningDateEndDate(state, value: string) {
            state.supervisorArchiveListSearchPlanningDateEndDate = value;
        },
        setSupervisorArchiveListSearchUnloadingPlanningDateEndDate(state, value: string) {
            state.supervisorArchiveListSearchUnloadingPlanningDateEndDate = value;
        },
        setSupervisorArchiveListSearchRealDateStartDate(state, value: string) {
            state.supervisorArchiveListSearchRealDateStartDate = value;
        },
        setSupervisorArchiveListSearchUnloadingRealDateStartDate(state, value: string) {
            state.supervisorArchiveListSearchUnloadingRealDateStartDate = value;
        },
        setSupervisorArchiveListSearchRealDateEndDate(state, value: string) {
            state.supervisorArchiveListSearchRealDateEndDate = value;
        },
        setSupervisorArchiveListSearchUnloadingRealDateEndDate(state, value: string) {
            state.supervisorArchiveListSearchUnloadingRealDateEndDate = value;
        },
        setSupervisorArchiveListSearchIsStorno(state, value: number) {
            state.supervisorArchiveListSearchIsStorno = value;
        },
        setSupervisorArchiveListPagerOptions(state, value) {
            state.supervisorArchiveListPagerOptions = value;
        },
        setIsSupervisorPreliminary(state, value) {
            state.isSupervisorPreliminary = value;
        },
        setPreliminarySupervisorList(state, value: SupervisorRestModel[]) {
            state.preliminarySupervisorList = value;
        },
        setPreliminarySupervisorListLoading(state, value: boolean) {
            state.preliminarySupervisorListLoading = value;
        },
        setPreliminarySupervisorListTotalCount(state, value: number) {
            state.preliminarySupervisorListTotalCount = value;
            if ((state.preliminarySupervisorListPagerOptions.page * state.preliminarySupervisorListPagerOptions.itemsPerPage) >= (value + state.preliminarySupervisorListPagerOptions.itemsPerPage)) {
                state.preliminarySupervisorListPagerOptions.page = 1;
            }
        },
        setPreliminarySupervisorListPagerOptions(state, value) {
            state.preliminarySupervisorListPagerOptions = value;
        },
        setSupervisorIntegrations(state, value: SupervisorIntegrationRestModel[]) {
            state.supervisorIntegrations = value;
        },
        setShowIntegrationDialog(state, value: boolean) {
            state.showIntegrationDialog = value;
        },
        setIntegrationDialogTitle(state, value: string) {
            state.integrationDialogTitle = value;
        },
        setIntegrationDialogResolve(state, value: any) {
            state.integrationDialogResolve = value;
        },
        setShowSendingEmailDialog(state, value: boolean) {
            state.showSendingEmailDialog = value;
        },
        setSendingEmailDialogTitle(state, value: string) {
            state.sendingEmailDialogTitle = value;
        },
        setSendingEmailDialogResolve(state, value: any) {
            state.sendingEmailDialogResolve = value;
        },
        setSendingEmailDialogSendToEmailAddressArray(state, value: SupervisorSendToEmailAddressModel[]) {
            state.sendingEmailDialogSendToEmailAddressArray = value;
        },
        setSendingEmailDialogSendFromEmailAddress(state, value: string) {
            state.sendingEmailDialogSendFromEmailAddress = value;
        },
        setSendingEmailDialogEmailNote(state, value: string) {
            state.sendingEmailDialogEmailNote = value;
        },
        setSendingEmailDialogCarrierOrderRequirementId(state, value: number) {
            state.sendingEmailDialogCarrierOrderRequirementId = value;
        },
        setCarrierOrderRequirementList(state, value: SupervisorCarrierDocumentOrderRequirementRestModel[]) {
            state.supervisorCarrierDocumentOrderRequirementList = value;
        },
        setShowSupervisorPlaceForCheckDialog(state, value: boolean) {
            state.showSupervisorPlaceForCheckDialog = value;
        },
        setSupervisorPlaceForCheckList(state, value: SupervisorPlaceRestModel[]) {
            state.supervisorPlaceForCheckList = value;
        },
        setIsSupervisorCustomChecked(state, value: any) {
            state.isSupervisorCustomChecked = value;
        },
        setOccurredErrorWhileSaving(state, value: boolean) {
            state.occurredErrorWhileSaving = value;
        },
        setOccurredErrorWhileSavingMessage(state, value: string) {
            state.occurredErrorWhileSavingMessage = value;
        },
        setSupervisorEditedByAnotherUser(state, value: boolean) {
            state.supervisorEditedByAnotherUser = value;
        },
        setSupervisorPlaceNamesFound(state, value: SupervisorPlaceRestModel[]) {
            state.supervisorPlaceNamesFound = value;
        },
        setSupervisorPlaceCitiesFound(state, value: SupervisorPlaceRestModel[]) {
            state.supervisorPlaceCitiesFound = value;
        },
        setSupervisorPlacePostalCodesFound(state, value: SupervisorPlaceRestModel[]) {
            state.supervisorPlacePostalCodesFound = value;
        },
        setSelectedContractorSender(state, value: ContractorSearchItemModel) {
            state.selectedContractorSender = value;
        },
        setSelectedContractorCarrier(state, value: ContractorSearchItemModel) {
            state.selectedContractorCarrier = value;
        },
        setSelectedContractorPayer(state, value: ContractorSearchItemModel) {
            state.selectedContractorPayer = value;
        },
        setSelectedContractorSenderListForFilter(state, value: ContractorSearchItemModel[]) {
            state.selectedContractorSenderListForFilter = value;
        },
        setSelectedExcludedContractorSenderListForFilter(state, value: ContractorSearchItemModel[]) {
            state.selectedExcludedContractorSenderListForFilter = value;
        },
        setSelectedContractorPayerListForFilter(state, value: ContractorSearchItemModel[]) {
            state.selectedContractorPayerListForFilter = value;
        },
        setSelectedContractorCarrierListForFilter(state, value: ContractorSearchItemModel[]) {
            state.selectedContractorCarrierListForFilter = value;
        },
        setContractorSenderFound(state, value: ContractorSearchItemModel[]) {
            state.contractorSenderFound = value;
        },
        setExcludedContractorSenderFound(state, value: ContractorSearchItemModel[]) {
            state.excludedContractorSenderFound = value;
        },
        setContractorCarrierFound(state, value: ContractorSearchItemModel[]) {
            state.contractorCarrierFound = value;
        },
        setContractorPayerFound(state, value: ContractorSearchItemModel[]) {
            state.contractorPayerFound = value;
        },
        setIsDroppedFromPreliminaryList(state, value: boolean) {
            state.isDroppedFromPreliminaryList = value;
        },
        setShowContractorDialog(state, value: boolean) {
            state.showContractorDialog = value;
        },
        setContractorDialogTitle(state, value: string) {
            state.contractorDialogTitle = value;
        },
        setContractorDialogContractorTypes(state, value: string[]) {
            state.contractorDialogContractorTypes = value;
        },
        setContractorDialogResolve(state, value: any) {
            state.contractorDialogResolve = value;
        },
        setContractorDialogContractorIsEdited(state, value: any) {
            state.contractorDialogContractorIsEdited = value;
        },
        setSupervisorMementoListCount(state, value: number) {
            state.supervisorMementoListCount = value;
        },
        setSupervisorMementoList(state, value: SupervisorMementoItemRestModel[]) {
            state.supervisorMementoList = value;
        },
        setIsSupervisorInHistoricalState(state, value: boolean) {
            state.isSupervisorInHistoricalState = value;
        },
        setShowAttachmentDialog(state, value: boolean) {
            state.showAttachmentDialog = value;
        },
        setAttachmentDialogResolve(state, value: any) {
            state.attachmentDialogResolve = value;
        },
        setAttachmentList(state, value: SupervisorAttachmentRestModel[]) {
            state.attachmentList = value;
        },
        setAttachmentDialogAttachments(state, value: any[]) {
            state.attachmentDialogAttachments = value;
        },
        setCurrentAttachment(state, value: string) {
            state.currentAttachment = value;
        },
        setProcedureList(state, value: SupervisorProcedureRestModel[]) {
            state.procedureList = value;
        },
        setShowProcedureDialog(state, value: boolean) {
            state.showProcedureDialog = value;
        },
        setProcedureDialogResolve(state, value: any) {
            state.procedureDialogResolve = value;
        },
        setProcedureDialogAttachments(state, value: any[]) {
            state.procedureDialogAttachments = value;
        },
        setProcedure(state, value: SupervisorProcedureRestModel) {
            state.procedure = value;
        },
        setProcedureFileList(state, value: ListItemNumberModel[]) {
            state.procedureFileList = value;
        },
        setShowSupervisorSettlementDialog(state, value: boolean) {
            state.showSupervisorSettlementDialog = value;
        },
        setSupervisorSettlementDialogResolve(state, value: any) {
            state.supervisorSettlementDialogResolve = value;
        },
        setSupervisorSearch(state, value: SupervisorRestModel[]) {
            state.supervisorSearch = value;
        },
        setIsSupervisorCopied(state, value: boolean) {
            state.isSupervisorCopied = value;
        },
        setSupervisorBaseToCopy(state, value: SupervisorRestModel) {
            state.supervisorBaseToCopy = value;
        },
        setShowSaveSupervisorDialog(state, value: boolean) {
            state.showSaveSupervisorDialog = value;
        },
        setTitleSaveSupervisorDialog(state, value: string) {
            state.titleSaveSupervisorDialog = value;
        },
        setMessageSaveSupervisorDialog(state, value: string) {
            state.messageSaveSupervisorDialog = value;
        },
        setResolveSaveSupervisorDialog(state, value: any) {
            state.resolveSaveSupervisorDialog = value;
        },
        setShowSendingSmsDialog(state, value: boolean) {
            state.showSendingSmsDialog = value;
        },
        setSendingSmsDialogPhoneNumber(state, value: string) {
            state.sendingSmsDialogPhoneNumber = value;
        },
        setSendingSmsDialogResolve(state, value: any) {
            state.sendingSmsDialogResolve = value;
        },
        setContractorContactPerson(state, value: ContractorContactPersonRestModel) {
            state.contractorContactPerson = value;
        },
        setShowContractorContactPersonDialog(state, value: boolean) {
            state.showContractorContactPersonDialog = value;
        },
        setContractorContactPersonDialogResolve(state, value: any) {
            state.contractorContactPersonDialogResolve = value;
        },
    },
    actions: {
        supervisorListCleanPageOptionsForAllList(context) {
            const { commit } = _moduleActionContext(context);
            const filterOptions = {
                page: 1,
                itemsPerPage: 10
            }
            commit.setSupervisorListSearchText("");
            commit.setSupervisorListSearchPlacePostalCode("");
            commit.setSupervisorListSearchPhoneNumber("");
            commit.setSupervisorListPagerOptions(filterOptions);
            commit.setPreliminarySupervisorListPagerOptions(filterOptions);
            commit.setSupervisorCheckpointsListPagerOptions(filterOptions);
        },
        loadSupervisorList(context,
            payload: { requestModel: SupervisorListRequestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setSupervisorListLoading(true);
                axiosInstance
                    .post("/api/supervisor/list", payload.requestModel)
                    .then((resp: AxiosResponse) => {
                        commit.setSupervisorList(resp.data.supervisorItems);
                        commit.setSupervisorListTotalCount(resp.data.supervisorItemsTotalCount)

                        commit.setSupervisorExcludedCustomersList(resp.data.supervisorExcludedContractorItems);
                        commit.setSupervisorExcludedCustomersListTotalCount(resp.data.supervisorExcludedContractorItemsTotalCount)

                        commit.setSupervisorCheckpointList(resp.data.supervisorCheckpointItems)
                        commit.setSupervisorCheckpointListTotalCount(resp.data.supervisorCheckpointItemsTotalCount);

                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setSupervisorListLoading(false);
                    })
            })
        },
        loadSupervisorArchiveList(context, payload: { requestModel: SupervisorArchiveListRequestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setSupervisorArchiveListLoading(true);
                axiosInstance
                    .post("/api/supervisor/archive-list", payload.requestModel)
                    .then((resp: AxiosResponse) => {
                        commit.setSupervisorArchiveList(resp.data.supervisorItems);
                        commit.setSupervisorArchiveListTotalCount(resp.data.supervisorItemsTotalCount)
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setSupervisorArchiveListLoading(false);
                    })
            })
        },
        downloadXlsReportSupervisorArchiveList(context, payload: { requestModel: SupervisorArchiveListRequestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((reject) => {
                commit.setSupervisorArchiveListLoading(true);
                axiosInstance
                    .post("/api/supervisor/export-archive-list/", payload.requestModel, {
                        responseType: "blob"
                    })
                    .then((resp: AxiosResponse) => {
                        saveFile(resp);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setSupervisorArchiveListLoading(false);
                    })
            });
        },
        downloadDetailedXlsReportSupervisorArchiveList(context, payload: { requestModel: SupervisorArchiveListRequestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((reject) => {
                commit.setSupervisorArchiveListLoading(true);
                axiosInstance
                    .post("/api/supervisor/export-detailed-archive-list/", payload.requestModel, {
                        responseType: "blob"
                    })
                    .then((resp: AxiosResponse) => {
                        saveFile(resp);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setSupervisorArchiveListLoading(false);
                    })
            });
        },
        downloadXlsReportSupervisorArchiveListForCustomer(context, payload: { requestModel: SupervisorArchiveListRequestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((reject) => {
                commit.setSupervisorArchiveListLoading(true);
                axiosInstance
                    .post("/api/supervisor/export-archive-list-for-customer/", payload.requestModel, {
                        responseType: "blob"
                    })
                    .then((resp: AxiosResponse) => {
                        saveFile(resp);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setSupervisorArchiveListLoading(false);
                    })
            });
        },
        async loadSupervisor(context, payload: { id: number, oldSupervisorId?: string | null, selectedPlacesIdcsArray?: string }) {
            const { state, commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                if (!isNaN(payload.id)) {
                    axiosInstance
                        .get("/api/supervisor/" + payload.id)
                        .then((resp: AxiosResponse) => {
                            commit.setSupervisor(resp.data);
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                }
                else if (payload.oldSupervisorId) {
                    const params = {
                        oldSupervisorId: payload.oldSupervisorId,
                        selectedPlacesIdcsArray: payload.selectedPlacesIdcsArray?.split(','),
                    };
                    axiosInstance
                        .get("/api/supervisor/createNewSupervisorBasedOnOldSupervisor", { params })
                        .then((resp: AxiosResponse) => {
                            commit.setSupervisor(resp.data)
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                }
                else {
                    const params = {
                        isPreliminary: state.isSupervisorPreliminary
                    };
                    axiosInstance
                        .get("/api/supervisor/temporary", { params })
                        .then((resp: AxiosResponse) => {
                            commit.setSupervisor(resp.data)
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                }
            })
        },
        async reloadSupervisorEditedAtValue(context, payload: { id: number }) {
            const { state, commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/supervisor/editedAt/" + payload.id)
                    .then((resp: AxiosResponse) => {
                        commit.setSupervisorEditedAtValue(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        async saveSupervisor(context, payload?: { addingSupervisorFromPreliminary: boolean }) {
            const { state, commit, dispatch } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                const addingSupervisorFromPreliminary = payload?.addingSupervisorFromPreliminary == true ? true : false;
                axiosInstance
                    .post("/api/supervisor/save/" + addingSupervisorFromPreliminary, state.supervisor)
                    .then(async (resp: AxiosResponse) => {
                        commit.setValidationError({});

                        const returnToSupervisorList = await dispatch.openSaveSupervisorDialog({
                            showSaveSupervisorDialog: true,
                            title: i18n.t("supervisor.supervisorOrderHasBeenSaved").toString(),
                            message: i18n.t("supervisor.orderId").toString() + ": " + resp.data.orderId,
                        });

                        resolve(returnToSupervisorList);
                    })
                    .catch((err: AxiosError) => {
                        if (err.response && err.response.data.errors) {
                            commit.setValidationError(err.response.data.errors);
                            resolve(err.response.data.errors)
                        }

                        if (err.response && err.response.data && err.response.data.message) {
                            commit.setOccurredErrorWhileSavingMessage(err.response.data.message);
                            commit.setOccurredErrorWhileSaving(true);
                        }

                        if (err.response?.status.toString() == "409") {
                            commit.setSupervisorEditedByAnotherUser(true);
                        }
                        reject(err)
                    });
            })
        },
        async sendSupervisorCarrierDocumentEmail(context, payload: { supervisorId: number, contractorCarrierId: number }) {
            const { state } = _moduleActionContext(context);
            const sendToEmailAddressArray = [] as string[];
            state.sendingEmailDialogSendToEmailAddressArray.forEach((model: SupervisorSendToEmailAddressModel) => {
                sendToEmailAddressArray.push(model.emailAddress);
            })
            const params = {
                supervisorId: payload.supervisorId,
                contractorCarrierId: payload.contractorCarrierId,
                carrierOrderRequirementId: state.sendingEmailDialogCarrierOrderRequirementId,
                sendToEmailAddressArray: sendToEmailAddressArray,
                sendFromEmailAddress: state.sendingEmailDialogSendFromEmailAddress,
                emailNote: state.sendingEmailDialogEmailNote,
            }
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisor/sendSupervisorCarrierDocumentEmail/", params)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        async importSupervisorClick(context) {
            const { state, commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisor/import/", state.supervisor)
                    .then(async (resp: AxiosResponse) => {
                        await resp.data.places.forEach((place: SupervisorPlaceRestModel) => {
                            place.isLocationConfirmedByUser = false;
                            place.id = undefined;
                        })
                        await commit.setSupervisor(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => {
                        reject(err)
                    });
            })
        },
        async changeSupervisorCustomCheck(context, payload: { supervisorId: number, isChecked: boolean }) {
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisor/changeSupervisorCustomCheck/" + payload.supervisorId + "/" + payload.isChecked)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        async changeSupervisorArchiveValue(context, payload: { supervisorId: number, isArchived: boolean }) {
            const { rootDispatch } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisor/changeSupervisorArchiveValue/" + payload.supervisorId + "/" + payload.isArchived)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                        if (resp.data == false) {
                            rootDispatch.confirm.openConfirmDialog({
                                showConfirmDialog: true,
                                title: i18n.t("supervisor.supervisorCannotBeArchived").toString(),
                                message: i18n.t("supervisor.supervisorCannotBeArchivedMessage").toString(),
                                links: [],
                                options: { buttonColor: "error", layoutColor: "error" },
                                buttonType: "ok"
                            })
                        }
                        return resp.data;
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        async changeSupervisorBranchId(context) {
            const { state, commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisor/changeSupervisorBranchId", state.supervisor)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                        commit.setSupervisor(resp.data);
                        return resp.data;
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        downloadFile(context, payload: { fileId: number, forSupervisorMemento: boolean }) {
            return new Promise((reject) => {
                axiosInstance
                    .get("/api/supervisor/file/" + payload.fileId + "/" + payload.forSupervisorMemento, {
                        responseType: "blob"
                    })
                    .then((resp: AxiosResponse) => {
                        const blob = new Blob([resp.data], {
                            type: resp.headers["content-type"]
                        });
                        const contentDisposition = resp.headers["content-disposition"];
                        const filename = contentDisposition
                            .split(";")[1]
                            .split("=")[1]
                            .replace(/^"|"$/g, '');
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        document.body.appendChild(a);
                        a.download = filename;
                        a.href = url;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        deleteSuperviorAttachment(context, payload: { fileId: any }) {
            const { state } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisor/file/delete/" + payload.fileId)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        getFileBytes(context, payload: { fileId: number }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((reject) => {
                axiosInstance
                    .get("/api/supervisor/fileBytes/" + payload.fileId)
                    .then((resp: AxiosResponse) => {
                        commit.setCurrentAttachment(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        async setFileAsCmr(context, payload: { fileId: number, isChecked: boolean }) {
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisor/setFileAsCmr/" + payload.fileId + "/" + payload.isChecked)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        openAttachmentDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setAttachmentDialogAttachments([] as any[]);
            commit.setShowAttachmentDialog(true);
            return new Promise(resolve => {
                commit.setAttachmentDialogResolve(resolve);
            })
        },
        isConfirmedAttachmentDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.attachmentDialogResolve(true);
            commit.setShowAttachmentDialog(false);
        },
        isNotConfirmedAttachmentDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowAttachmentDialog(false);
        },
        saveAttachments(context, payload) {
            const { state, dispatch, rootDispatch } = _moduleActionContext(context);
            const formData = new FormData();

            for (const index in state.attachmentDialogAttachments) {
                formData.append("files", payload.files[index]);
                formData.append("isCmr", payload.isCmr);
            }

            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisor/" + state.supervisor.id + "/attachments/save", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then((resp: AxiosResponse) => {
                        const response = resp.data as SupervisorSaveAttachmentResponseModel;
                        if (response.statusCode == 415) {
                            rootDispatch.confirm.openConfirmDialog({
                                showConfirmDialog: true,
                                title: i18n.t("common.wrongFileFormatTitle").toString(),
                                message: i18n.t("common.wrongFileFormatTitleMessageFirstPart").toString() + response.fileNameList.join(", ") + i18n.t("common.wrongFileFormatTitleMessageLastPart").toString(),
                                links: [],
                                options: { buttonColor: "error", layoutColor: "error" },
                                buttonType: "ok"
                            })
                        }
                        else if (response.statusCode == 500) {
                            rootDispatch.confirm.openConfirmDialog({
                                showConfirmDialog: true,
                                title: i18n.t("common.serverErrorTitle").toString(),
                                message: i18n.t("common.serverErrorMessage").toString() + response.message,
                                links: [],
                                options: { buttonColor: "error", layoutColor: "error" },
                                buttonType: "ok"
                            })
                        }
                        dispatch.loadSupervisorAttachmentList();
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => {
                        reject(err)
                    });
            });
        },
        loadSupervisorAttachmentList(context, payload: { mementoPoint: number | null } = { mementoPoint: null }) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                const params = {
                    mementoPoint: payload.mementoPoint,
                }
                axiosInstance
                    .get("/api/supervisor/" + state.supervisor.id + "/attachment/list", { params })
                    .then((resp: AxiosResponse) => {
                        commit.setAttachmentList(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        openProcedureDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setProcedureDialogAttachments([] as any[]);
            commit.setShowProcedureDialog(true);
            return new Promise(resolve => {
                commit.setProcedureDialogResolve(resolve);
            })
        },
        isConfirmedProcedureDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.procedureDialogResolve(true);
            commit.setShowProcedureDialog(false);
        },
        isNotConfirmedProcedureDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowProcedureDialog(false);
        },
        loadSupervisorProcedureList(context) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/supervisor/" + state.supervisor.id + "/procedure/list")
                    .then((resp: AxiosResponse) => {
                        commit.setProcedureList(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        saveProcedure(context, payload) {
            const { state, dispatch, rootDispatch } = _moduleActionContext(context);
            const formData = new FormData();

            for (const index in payload.files) {
                formData.append("files", payload.files[index]);
            }

            formData.append("data", JSON.stringify({
                procedureId: payload.procedureId,
                vin: payload.vin,
                isApprovedByManager: payload.isApprovedByManager,
                isApprovedByDisponent: payload.isApprovedByDisponent,
                assignedToUserAsManagerId: payload.assignedToUserAsManagerId
            }));

            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisor/" + state.supervisor.id + "/procedure/save", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then((resp) => {
                        const response = resp.data;
                        if (response.statusCode == 415) {
                            rootDispatch.confirm.openConfirmDialog({
                                showConfirmDialog: true,
                                title: i18n.t("common.wrongFileFormatTitle").toString(),
                                message: i18n.t("common.wrongFileFormatTitleMessageFirstPart").toString() + response.fileNameList.join(", ") + i18n.t("common.wrongFileFormatTitleMessageLastPart").toString(),
                                links: [],
                                options: { buttonColor: "error", layoutColor: "error" },
                                buttonType: "ok"
                            });
                        } else if (response.statusCode == 500) {
                            rootDispatch.confirm.openConfirmDialog({
                                showConfirmDialog: true,
                                title: i18n.t("common.serverErrorTitle").toString(),
                                message: i18n.t("common.serverErrorMessage").toString() + response.message,
                                links: [],
                                options: { buttonColor: "error", layoutColor: "error" },
                                buttonType: "ok"
                            });
                        }
                        dispatch.loadSupervisorProcedureList();
                        resolve(resp.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        loadProcedure(context, payload: { procedureId: number }) {
            const { state, commit, dispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/supervisor/" + state.supervisor.id + "/procedure/" + payload.procedureId)
                    .then((resp: AxiosResponse) => {
                        commit.setProcedure(resp.data);
                        dispatch.loadProcedureFileList();
                        dispatch.openProcedureDialog();
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        deleteSuperviorProcedure(context, payload: { procedureId: number }) {
            const { state } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisor/" + state.supervisor.id + "/procedure/delete/" + payload.procedureId)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        loadProcedureFileList(context) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/supervisor/" + state.supervisor.id + "/procedure/" + state.procedure.id + "/file/list")
                    .then((resp: AxiosResponse) => {
                        commit.setProcedureFileList(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        downloadProcedureFile(context, payload: { id: number }) {
            const { state } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/supervisor/" + state.supervisor.id + "/procedure/" + state.procedure.id + "/file/download/" + payload.id, {
                        responseType: "arraybuffer"
                    })
                    .then((resp: AxiosResponse) => {
                        const blob = new Blob([resp.data], {
                            type: resp.headers["content-type"]
                        });
                        const contentDisposition = resp.headers["content-disposition"];
                        const filename = contentDisposition
                            .split(";")[1]
                            .split("=")[1]
                            .replace('"', "")
                            .replace('"', "");

                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        document.body.appendChild(a);
                        a.download = filename;
                        a.href = url;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        downloadSupervisorProcedureDocument(context, payload: { procedureId: number }) {
            const { state } = _moduleActionContext(context);
            return new Promise((reject) => {
                axiosInstance
                    .get("/api/supervisor/" + state.supervisor.id + "/procedure/" + payload.procedureId + "/document/download/", {
                        responseType: "blob"
                    })
                    .then((resp: AxiosResponse) => {
                        const blob = new Blob([resp.data], {
                            type: resp.headers["content-type"]
                        });
                        const contentDisposition = resp.headers["content-disposition"];
                        const filename = contentDisposition
                            .split(";")[1]
                            .split("=")[1]
                            .replace(/^"|"$/g, '');
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        document.body.appendChild(a);
                        a.download = filename;
                        a.href = url;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        async mobileAppCancelOrder(context, payload: { supervisorId: number }) {
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisor/mobileAppCancelOrder/" + payload.supervisorId)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        async mobileAppOrderItemStateChange(context, payload: { supervisorId: number, supervisorPlaceId: number }) {
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisor/mobileAppOrderItemStateChange/" + payload.supervisorId + "/" + payload.supervisorPlaceId)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        loadPreliminarySupervisorList(context,
            payload: { requestModel: SupervisorListRequestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setSupervisorListLoading(true);
                axiosInstance
                    .post("/api/supervisor/preliminary-list", payload.requestModel)
                    .then((resp: AxiosResponse) => {
                        commit.setPreliminarySupervisorList(resp.data.supervisorItems);
                        commit.setPreliminarySupervisorListTotalCount(resp.data.supervisorItemsTotalCount)
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setSupervisorListLoading(false);
                    })
            })
        },
        getSupervisorIntegrations(context, payload: { supervisorId: number, forSupervisorMemento: boolean }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/supervisor/integration-by-supervisor-id/" + payload.supervisorId + "/" + payload.forSupervisorMemento)
                    .then((resp: AxiosResponse) => {
                        commit.setSupervisorIntegrations(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        updateSupervisorIntegrations(context) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisor/integration-by-supervisor-id/update/" + state.supervisor.id, state.supervisorIntegrations)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        isConfirmedIntegrationDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.integrationDialogResolve(true);
            commit.setShowIntegrationDialog(false);
        },
        isNotConfirmedIntegrationDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowIntegrationDialog(false);
        },
        openIntegrationDialog(
            context,
            payload: {
                showDialog: boolean;
                title: string;
            }
        ) {
            const { commit } = _moduleActionContext(context);
            commit.setShowIntegrationDialog(payload.showDialog);
            commit.setIntegrationDialogTitle(payload.title);
            return new Promise(resolve => {
                commit.setIntegrationDialogResolve(resolve);
            });
        },
        isConfirmedSendingEmailDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.sendingEmailDialogResolve(true);
            commit.setShowSendingEmailDialog(false);
        },
        isNotConfirmedSendingEmailDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.sendingEmailDialogResolve(false);
            commit.setShowSendingEmailDialog(false);
        },
        openSendingEmailDialog(context, payload: { showDialog: boolean; title: string; }) {
            const { commit } = _moduleActionContext(context);
            commit.setShowSendingEmailDialog(payload.showDialog);
            commit.setSendingEmailDialogTitle(payload.title);
            return new Promise(resolve => {
                commit.setSendingEmailDialogResolve(resolve);
            });
        },
        openSupervisorSettlementDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowSupervisorSettlementDialog(true);
            return new Promise((resolve, reject) => {
                commit.setSupervisorSettlementDialogResolve(resolve);
            });
        },
        isConfirmedSupervisorSettlementDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.supervisorSettlementDialogResolve(true);
            commit.setShowSupervisorSettlementDialog(false);
        },
        isNotConfirmedSupervisorSettlementDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.supervisorSettlementDialogResolve(false);
            commit.setShowSupervisorSettlementDialog(false);
        },
        loadSupervisorPlaceForCheckList(context, payload) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/supervisor/getSupervisorPlaceForCheckList/" + payload)
                    .then((resp: AxiosResponse) => {
                        commit.setSupervisorPlaceForCheckList(resp.data)
                        resolve(resp.data);
                    })
                    .catch((error: AxiosError) => reject(error));
            })
        },
        async changeSupervisorPlaceArrivalTime(context, payload: { supervisorPlaceId: number, isChecked: boolean }) {
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisor/changeSupervisorPlaceArrivalTime/" + payload.supervisorPlaceId + "/" + payload.isChecked)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        searchSupervisorPlaceItems(context, payload: { searchText: string, searchTextType: string }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisor/search-supervisor-place-items", { searchText: payload.searchText, searchTextType: payload.searchTextType })
                    .then((resp: AxiosResponse) => {
                        if (payload.searchTextType == "name") {
                            commit.setSupervisorPlaceNamesFound(resp.data);
                        }
                        else if (payload.searchTextType == "city") {
                            commit.setSupervisorPlaceCitiesFound(resp.data);
                        }
                        else if (payload.searchTextType == "postalCode") {
                            commit.setSupervisorPlacePostalCodesFound(resp.data);
                        }
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        async assignUserToSupervisor(context, payload: { supervisorId: number, isAssigned: boolean }) {
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisor/assignUserToSupervisor/" + payload.supervisorId + "/" + payload.isAssigned)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        async duplicateSupervisor(context, payload: { id: number, fullDuplication: boolean }) {
            const { state, commit, dispatch } = _moduleActionContext(context);
            await dispatch.loadSupervisor({ id: payload.id });
            commit.setSupervisorBaseToCopy(state.supervisor);

            return await new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/supervisor/" + payload.id + "/duplicate/" + payload.fullDuplication)
                    .then(async (resp: AxiosResponse) => {
                        commit.setSupervisor(resp.data);
                        await resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        async getSupervisorCarrierDocumentOrderRequirementList(context, payload: { forSupervisor: boolean }) {
            const { commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/supervisor/getSupervisorCarrierDocumentOrderRequirementList/" + payload.forSupervisor)
                    .then((resp: AxiosResponse) => {
                        commit.setCarrierOrderRequirementList(resp.data)
                        resolve(resp.data);
                    })
                    .catch((error: AxiosError) => reject(error));
            })
        },
        isConfirmedContractorDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.contractorDialogResolve(true);
            commit.setShowContractorDialog(false);
        },
        isNotConfirmedContractorDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowContractorDialog(false);
        },
        openContractorDialog(
            context,
            payload: {
                showDialog: boolean;
                title: string;
                contractorTypes: string[];
                isContractorEdited: boolean;
            }
        ) {
            const { commit } = _moduleActionContext(context);
            commit.setShowContractorDialog(payload.showDialog);
            commit.setContractorDialogTitle(payload.title);
            commit.setContractorDialogContractorTypes(payload.contractorTypes);
            commit.setContractorDialogContractorIsEdited(payload.isContractorEdited);
            return new Promise(resolve => {
                commit.setContractorDialogResolve(resolve);
            });
        },
        async getSupervisorMementoList(context, payload: { supervisorId: number }) {
            const { commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/supervisor/getSupervisorMementoList/" + payload.supervisorId)
                    .then((resp: AxiosResponse) => {
                        commit.setSupervisorMementoListCount(resp.data.supervisorMementosCount)
                        commit.setSupervisorMementoList(resp.data.supervisorMementoList)
                        resolve(resp.data);
                    })
                    .catch((error: AxiosError) => reject(error));
            })
        },
        async getSupervisorMementoPoint(context, payload: { mementoPoint: number }) {
            const { state, commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/supervisor/getSupervisorMementoPoint/" + state.supervisor.id + '/' + payload.mementoPoint)
                    .then((resp: AxiosResponse) => {
                        commit.setSupervisor(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            }
            )
        },
        async searchSupervisorsForSettlement(context, payload: { searchText: string }) {
            const { commit } = _moduleActionContext(context)
            {
                return await new Promise((resolve, reject) => {
                    axiosInstance
                        .post("/api/supervisor/search-supervisors-for-settlement", { searchText: payload.searchText })
                        .then(async (resp: AxiosResponse) => {
                            commit.setSupervisorSearch(resp.data);
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                });
            }
        },
        downloadPDFSupervisorDocument(context, payload: { supervisorId: number, fullVersion: boolean }) {
            return new Promise((reject) => {
                axiosInstance
                    .get("/api/supervisor/" + payload.supervisorId + "/document/" + payload.fullVersion, {
                        responseType: "blob"
                    })
                    .then((resp: AxiosResponse) => {
                        const blob = new Blob([resp.data], {
                            type: resp.headers["content-type"]
                        });
                        const contentDisposition = resp.headers["content-disposition"];
                        const filename = contentDisposition
                            .split(";")[1]
                            .split("=")[1]
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        document.body.appendChild(a);
                        a.download = filename;
                        a.href = url;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        updateSupervisorLockedForEditingUntilValue(context, payload: { supervisorId: number }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisor/updateSupervisorLockedForEditingUntilValue/" + payload.supervisorId)
                    .then((resp: AxiosResponse) => {
                        commit.setSupervisorEditedAtValue(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        clearSupervisorLockedForEditingUntilValue(context, payload: { supervisorId: number }) {
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisor/clearSupervisorLockedForEditingUntilValue/" + payload.supervisorId)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        isConfirmedSaveSupervisorDialog(context, payload: { isConfirmed: boolean }) {
            const { state, commit } = _moduleActionContext(context);
            state.resolveSaveSupervisorDialog(true);
            commit.setShowSaveSupervisorDialog(false);
        },

        isNotConfirmedSaveSupervisorDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.resolveSaveSupervisorDialog(false);
            commit.setShowSaveSupervisorDialog(false);
        },
        openSaveSupervisorDialog(
            context,
            payload: {
                showSaveSupervisorDialog: boolean;
                title: string;
                message: string;
            }
        ) {
            const { commit } = _moduleActionContext(context);
            commit.setShowSaveSupervisorDialog(payload.showSaveSupervisorDialog);
            commit.setTitleSaveSupervisorDialog(payload.title);
            commit.setMessageSaveSupervisorDialog(payload.message);
            return new Promise((resolve, reject) => {
                commit.setResolveSaveSupervisorDialog(resolve);
            });
        },
        callUpdateSha1ContentInSupervisorFilesStoredProcedure(context) {
            const { state } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/supervisor/callUpdateSha1ContentInSupervisorFilesStoredProcedure")
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        callUpdateSha1ContentInSupervisorFileMementosStoredProcedure(context) {
            const { state } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/supervisor/callUpdateSha1ContentInSupervisorFileMementosStoredProcedure")
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        callUpdateSupervisorFileIdInSupervisorFileMementosByStoredProcedure(context) {
            const { state } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/supervisor/callUpdateSupervisorFileIdInSupervisorFileMementosByStoredProcedure")
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        openSendingSmsDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowSendingSmsDialog(true);
            return new Promise(resolve => {
                commit.setSendingSmsDialogResolve(resolve);
            })
        },
        isConfirmedSendingSmsDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.sendingSmsDialogResolve(true);
            commit.setShowSendingSmsDialog(false);
        },
        isNotConfirmedSendingSmsDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowSendingSmsDialog(false);
        },
        async sendSmsMessage(context, payload: { phoneNumber: string, message: string, supervisorId: number }) {
            const request = {
                supervisorId: payload.supervisorId,
                phoneNumber: payload.phoneNumber,
                message: payload.message
            }
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisor/sendSupervisorCarrierNotificationSms", request)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        closeContractorContactPersonDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowContractorContactPersonDialog(false);
        },
        isConfirmedContractorContactPersonDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.contractorContactPersonDialogResolve(true);
            commit.setShowContractorContactPersonDialog(false);
        },
        isNotConfirmedContractorContactPersonDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowContractorContactPersonDialog(false);
        },
        async openContractorContactPersonDialog(
            context,
            payload: {
                showDialog: boolean;
            }
        ) {
            const { commit } = _moduleActionContext(context);
            commit.setShowContractorContactPersonDialog(payload.showDialog);
            return await new Promise(resolve => {
                commit.setContractorContactPersonDialogResolve(resolve);
            });
        },
    }
});

export default supervisorModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, supervisorModule)