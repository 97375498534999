import Vue from "vue";
import { Component } from "vue-property-decorator";
import SupervisorCarrierDocumentOrderRequirementRestModel from "../../../../models/rest/supervisor/supervisorCarrierDocumentOrderRequirementRestModel";
import ConfirmDialog from "../../../../layouts/confirmDialog/confirmDialog.vue";
import SupervisorCarrierDocumentOrderRequirementDialog from "./supervisor-carrier-document-order-requirement-dialog.vue";
import DepartmentItemRestModel from "../../../../models/rest/register/departmentItemRestModel";

@Component({
    components: {
        "confirm-dialog": ConfirmDialog,
        "supervisor-carrier-document-order-requirement-dialog": SupervisorCarrierDocumentOrderRequirementDialog
    }
})
export default class SupervisorCarrierDocumentOrderRequirementList extends Vue {
    get items(): SupervisorCarrierDocumentOrderRequirementRestModel[] {
        return this.$store.direct.state.supervisor.supervisorCarrierDocumentOrderRequirementList;
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get departments(): DepartmentItemRestModel[] {
        return this.$store.direct.state.contractor.contractorAssets.departments
    }

    itemsHeaders = [
        {
            text: this.$t("common.name"),
            value: "name"
        },
        {
            text: this.$t("common.assignedToDepartments"),
            value: "assignedToDepartments",
            sortable: false
        },
        {
            text: this.$t("common.isHidden"),
            align: "center",
            value: "isHidden",
            width: "4%",
            sortable: false
        },
        {
            text: this.$t("common.actions"),
            align: "center",
            value: "edit",
            width: "1%",
            sortable: false
        },
        {
            text: this.$t("common.hide"),
            align: "center",
            value: "hide",
            width: "1%",
            sortable: false
        }
    ];

    pagerOptions = {
        page: 1,
        itemsPerPage: 10
    };

    mounted() {
        this.$store.direct.dispatch.contractor.loadContractorAssets();
        this.$store.direct.dispatch.supervisor.getSupervisorCarrierDocumentOrderRequirementList({ forSupervisor: false });
    }

    getAssignedToDepartmentNames(item: SupervisorCarrierDocumentOrderRequirementRestModel) {
        let departmentNames = new String();
        if (this.departments != undefined) {
            item.departmentIdcs.forEach((departmentId: number) => {
                const foundDepartment = this.departments.find((department: DepartmentItemRestModel) => department.value == departmentId)
                if (foundDepartment != undefined) {
                    departmentNames += foundDepartment.text + ", ";
                }
            })
        }
        return departmentNames;
    }

    itemCreateClick() {
        this.$store.direct.dispatch.register.showSupervisorCarrierDocumentOrderRequirementDialog({ name: "", content: "" } as SupervisorCarrierDocumentOrderRequirementRestModel);
    }

    itemEditClick(item: SupervisorCarrierDocumentOrderRequirementRestModel) {
        this.$store.direct.dispatch.register.showSupervisorCarrierDocumentOrderRequirementDialog(item);
    }

    itemHideClick(item: SupervisorCarrierDocumentOrderRequirementRestModel) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: "Hide carrier requirement",
                message: "Are you sure you want to hide this requirement?: " + item.name,
                links: [],
                options: { buttonColor: "error", layoutColor: "error" },
                buttonType: "yes/no"
            })
            .then(confirm => {
                if (confirm) {
                    this.$store.direct.dispatch.register.hideSupervisorCarrierDocumentOrderRequirement(item);
                }
            })
    }
}
