import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import SupervisorMessageTemplateRestModel from "../../../models/rest/supervisor/supervisorMessageTemplateRestModel";
import SupervisorRestModel from "../../../models/rest/supervisor/supervisorRestModel";

type LanguageKeys = 'messagePL' | 'messageEN' | 'messageDE';

@Component({})

export default class SendingSmsDialog extends Vue {
    language = "messagePL" as LanguageKeys;
    message = "";
    messageTemplate = null as null | SupervisorMessageTemplateRestModel;

    get showDialog(): boolean {
        return this.$store.direct.state.supervisor.showSendingSmsDialog;
    }

    get sendingSmsDialogPhoneNumber(): string {
        return this.$store.direct.state.supervisor.sendingSmsDialogPhoneNumber;
    }

    set sendingSmsDialogPhoneNumber(value: string) {
        this.$store.direct.commit.supervisor.setSendingSmsDialogPhoneNumber(value);
    }

    get messageTemplates(): SupervisorMessageTemplateRestModel[] {
        return this.$store.direct.state.contractor.contractorAssets.supervisorMessageTemplates;
    }

    get supervisor(): SupervisorRestModel {
        return this.$store.direct.state.supervisor.supervisor;
    }

    get currentUserFirstName(): string {
        return this.$store.direct.state.auth.currentUserFirstName;
    }

    @Watch("language")
    languageHandler(val: string) {
        this.updateMessage();
    }

    @Watch("messageTemplate")
    messageTemplateHandler(val: string) {
        this.updateMessage();
    }

    updateMessage() {
        const messageTemplate = this.messageTemplates.find((x: SupervisorMessageTemplateRestModel) => {
            return x.id == this.messageTemplate?.id;
        })

        const languageKey: LanguageKeys = this.language;
        if (messageTemplate != undefined) {
            let messageToReplace = messageTemplate[languageKey];

            messageToReplace = messageToReplace.replace("{NrRejestracyjny}", this.supervisor.registrationNumber);
            messageToReplace = messageToReplace.replace("{MiastoNastNieodznaczonyZaladunek}", this.supervisor.nextCheckCity);
            messageToReplace = messageToReplace.replace("{MiastoNastNieodznaczonyRozladunek}", this.supervisor.nextCheckCity);
            messageToReplace = messageToReplace.replace("{MiastoOstatniOdznaczonyRozladunek}", "{MiastoOstatniOdznaczonyRozladunek}");
            messageToReplace = messageToReplace.replace("{Uzytkownik}", this.currentUserFirstName);

            this.message = messageToReplace;
        }
    }

    cancelClick() {
        this.$store.direct.dispatch.supervisor.isNotConfirmedSendingSmsDialog();
    }

    async saveClick() {
        await this.$store.direct.dispatch.supervisor.sendSmsMessage({ phoneNumber: this.sendingSmsDialogPhoneNumber, message: this.message, supervisorId: this.supervisor.id });
        await this.$store.direct.dispatch.supervisor.isConfirmedSendingSmsDialog();
    }
}
