import Vue from "vue";
import { Component } from "vue-property-decorator";
import { axiosInstance } from "../../../plugins/axios";
import { AxiosResponse, AxiosError } from "axios";
import ContractorImportIdcsRestModel from "../../../models/rest/contractor/contractorImportIdcsRestModel";
import ContractorRestModel from "../../../models/rest/contractor/contractorRestModel";

@Component
export default class ImportContractors extends Vue {
    stepperState = 1;
    contractorIdcs: number[] = [];
    currentContractorIndex = 0;
    currentContractorId = 0;
    currentContractorName = ""
    currentContractorPercentage = 0;
    contractorIdcsInterlanCount = 0;
    contractorIdcsImportDone = false;
    contractorIdcsImportPage = 0;
    pauseResumeLabel = "Pause"
    isPaused = false;

    importContractorIdcs() {
        axiosInstance
            .get("/api/contractor/import-contractors-idcs/" + this.contractorIdcsImportPage)
            .then((resp: AxiosResponse) => {
                const result: ContractorImportIdcsRestModel = resp.data;
                this.contractorIdcsInterlanCount += result.interlanIdcsCount;
                this.contractorIdcs.push(...result.importContractorIdcs);
                this.contractorIdcsImportPage++;
                if (result.interlanIdcsCount > 0) {
                    this.importContractorIdcs();
                }
                else {
                    this.contractorIdcsImportDone = true;
                }
            })
    }

    importContractor() {
        if (this.currentContractorIndex <= this.contractorIdcs.length && this.isPaused == false) {
            this.currentContractorId = this.contractorIdcs[this.currentContractorIndex];
            axiosInstance
                .get("/api/contractor/import-contractor-by-id/" + this.currentContractorId)
                .then((resp: AxiosResponse) => {
                    const result: ContractorRestModel = resp.data;
                    this.currentContractorName = result.name;
                    this.currentContractorIndex++;
                    this.currentContractorPercentage = Math.floor(this.currentContractorIndex * 100 / this.contractorIdcs.length)
                    this.importContractor();
                })
        }
    }

    step1Click() {
        this.stepperState = 2;
        this.contractorIdcs = [];
        this.contractorIdcsImportDone = false;
        this.importContractorIdcs();
    }
    step2Click() {
        this.stepperState = 3;
        this.importContractor();
    }
    pauseResumeClick() {
        this.isPaused = !this.isPaused
        this.pauseResumeLabel = this.isPaused ? "Resume" : "Pause";
        this.importContractor();
    }
    resetClick() {
        this.isPaused = false;
        this.contractorIdcsImportPage = 0;
        this.contractorIdcs = [];
        this.stepperState = 1;
    }
}
